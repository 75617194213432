import React, { useReducer } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import { useTranslation } from "react-i18next";
import TopImage from "../../components/TopImage";
import { Helmet } from "react-helmet";

export default function Announcements() {
	const { t } = useTranslation();

	const announcementsList = [
		{
			term: t("announcementTitle1"),
			desc: t("announcementText1"),
		},
	];

	const initialState = {
		activeIndex: 0,
		activeGeneral: 0,
		activePayment: 0,
		activeSupport: 0,
	};

	const toggleAccordion = (category, index) => {
		dispatch({
			type: "SELECT_CATEGORY",
			payload: { category: category, index: index },
		});
	};

	const reducerMethod = (state, action) => {
		switch (action.payload.category) {
			case "active":
				return {
					...state,
					activeIndex: action.payload.index,
				};
			case "general":
				return {
					...state,
					activeGeneral: action.payload.index,
				};
			case "payments":
				return {
					...state,
					activePayment: action.payload.index,
				};
			case "support":
				return {
					...state,
					activeSupport: action.payload.index,
				};
			default:
				return state;
		}
	};
	const [state, dispatch] = useReducer(reducerMethod, initialState);

	return (
		<>
			<Helmet>
				<title>{t("announcements")}</title>
				<meta
					name="Crypto Dictionary - Key Terms and Definitions in Currency
                    Trading"
					content="Explore our Crypto dictionary to understand the essential
                    terms and definitions used in currency trading."
				/>
			</Helmet>
			<Navbar />
			<TopImage title={t("announcements")} />
			<section className="relative md:py-24 py-16">
				<div className="container">
					<div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
						<div className="lg:col-span-12 md:col-span-12">
							{/* <h5 className="text-2xl font-semibold">Buying Product</h5> */}
							<div
								id="accordion-collapseone"
								data-accordion="collapse"
								className="mt-6"
							>
								{announcementsList.map((item, index) => (
									<div id={`${item.term.charAt(0)}`}>
										<div
											key={index}
											className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4"
										>
											<h2
												className="text-base font-semibold"
												id="accordion-collapse-heading-1"
											>
												<button
													onClick={() => toggleAccordion("active", index)}
													type="button"
													className={`flex justify-between items-center p-5 w-full font-semibold text-start ${
														state.activeIndex === index
															? "bg-gray-50 dark:bg-slate-800 text-violet-600"
															: ""
													}`}
													data-accordion-target="#accordion-collapse-body-1"
													aria-expanded="true"
													aria-controls="accordion-collapse-body-1"
												>
													<span>{item.term}</span>
													<svg
														data-accordion-icon
														className="w-4 h-4 rotate-180 shrink-0"
														fill="currentColor"
														viewBox="0 0 20 20"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fillRule="evenodd"
															d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
															clipRule="evenodd"
														></path>
													</svg>
												</button>
											</h2>
											{state.activeIndex === index && (
												<div
													id="accordion-collapse-body-1"
													aria-labelledby="accordion-collapse-heading-1"
												>
													<div className="p-5">
														<p className="text-slate-400 dark:text-gray-400 mb-2">
															{item.desc}
														</p>
													</div>
												</div>
											)}
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
			<Switcher />
		</>
	);
}
