import React, { useReducer } from "react";
import Navbar from "../../components/navbar";
import { Link as Link2 } from "react-scroll";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TopImage from "../../components/TopImage";
import { Helmet } from "react-helmet";

export default function CryptoDictionary() {
	const { t } = useTranslation();

	const CryptoDictionary = [
		{
			term: t("term8"),
			desc: t("dictionaryDesc8"),
			definition: t("definition8"),
		},
		{
			term: t("term3"),
			desc: t("dictionaryDesc3"),
			definition: t("definition3"),
		},
		{
			term: t("term16"),
			desc: t("dictionaryDesc16"),
			definition: t("definition16"),
		},
		{
			term: t("term13"),
			desc: t("dictionaryDesc13"),
			definition: t("definition13"),
		},
		{
			term: t("term2"),
			desc: t("dictionaryDesc2"),
			definition: t("definition2"),
		},
		{
			term: t("term1"),
			desc: t("dictionaryDesc1"),
			definition: t("definition1"),
		},
		{
			term: t("term5"),
			desc: t("dictionaryDesc5"),
			definition: t("definition5"),
		},
		{
			term: t("term17"),
			desc: t("dictionaryDesc17"),
			definition: t("definition17"),
		},
		{
			term: t("term14"),
			desc: t("dictionaryDesc14"),
			definition: t("definition14"),
		},
		{
			term: t("term4"),
			desc: t("dictionaryDesc4"),
			definition: t("definition4"),
		},

		{
			term: t("term6"),
			desc: t("dictionaryDesc6"),
			definition: t("definition6"),
		},
		{
			term: t("term10"),
			desc: t("dictionaryDesc10"),
			definition: t("definition10"),
		},
		{
			term: t("term15"),
			desc: t("dictionaryDesc15"),
			definition: t("definition15"),
		},
		{
			term: t("term11"),
			desc: t("dictionaryDesc11"),
			definition: t("definition11"),
		},
		{
			term: t("term9"),
			desc: t("dictionaryDesc9"),
			definition: t("definition9"),
		},
		{
			term: t("term12"),
			desc: t("dictionaryDesc12"),
			definition: t("definition12"),
		},
		{
			term: t("term7"),
			desc: t("dictionaryDesc7"),
			definition: t("definition7"),
		},
	];

	CryptoDictionary.sort((a, b) => {
		const termA = a.term.toUpperCase();
		const termB = b.term.toUpperCase();

		if (termA < termB) {
			return -1;
		}
		if (termA > termB) {
			return 1;
		}
		return 0;
	});

	const initialState = {
		activeIndex: 0,
		activeGeneral: 0,
		activePayment: 0,
		activeSupport: 0,
	};

	const toggleAccordion = (category, index) => {
		dispatch({
			type: "SELECT_CATEGORY",
			payload: { category: category, index: index },
		});
	};

	const reducerMethod = (state, action) => {
		switch (action.payload.category) {
			case "active":
				return {
					...state,
					activeIndex: action.payload.index,
				};
			case "general":
				return {
					...state,
					activeGeneral: action.payload.index,
				};
			case "payments":
				return {
					...state,
					activePayment: action.payload.index,
				};
			case "support":
				return {
					...state,
					activeSupport: action.payload.index,
				};
			default:
				return state;
		}
	};
	const [state, dispatch] = useReducer(reducerMethod, initialState);
	const alphabet = [
		"A",
		"B",
		"C",
		"D",
		"E",
		"F",
		"G",
		"H",
		"I",
		"J",
		"K",
		"L",
		"M",
		"N",
		"O",
		"P",
		"Q",
		"R",
		"S",
		"T",
		"U",
		"V",
		"W",
		"X",
		"Y",
		"Z",
	];

	return (
		<>
			<Helmet>
				<title>{t("cryptocurrencyDictionary")}</title>
				<meta
					name="Crypto Dictionary - Key Terms and Definitions in Currency Trading"
					content="Explore our Crypto dictionary to understand the essential terms and definitions used in currency trading."
				/>
			</Helmet>
			<Navbar />
			<TopImage title={t("cryptocurrencyDictionary")} />
			<section className="relative md:py-24 py-16">
				<div className="container">
					<div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
						<div className="lg:col-span-12 md:col-span-12">
							<div>
								<div className="grid md:grid-cols-12 grid-cols-1 mt-8">
									<div className="md:col-span-12 text-center">
										<nav>
											<ul className="inline-flex items-center -space-x-px flex-wrap">
												{alphabet.map((char, key) => (
													<li>
														<Link2
															to={`${char}`}
															spy={true}
															smooth={true}
															hashSpy={true}
															offset={-120}
															key={key}
															className="w-10 h-10 inline-flex cursor-pointer justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
														>
															{char}
														</Link2>
													</li>
												))}
											</ul>
										</nav>
									</div>
								</div>
								{/* <h5 className="text-2xl font-semibold">Buying Product</h5> */}
								<div
									id="accordion-collapseone"
									data-accordion="collapse"
									className="mt-6"
								>
									{CryptoDictionary.map((item, index) => (
										<div id={`${item.term.charAt(0)}`}>
											<div
												key={index}
												className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4"
											>
												<h2
													className="text-base font-semibold"
													id="accordion-collapse-heading-1"
												>
													<button
														onClick={() => toggleAccordion("active", index)}
														type="button"
														className={`flex justify-between items-center p-5 w-full font-semibold text-start ${
															state.activeIndex === index
																? "bg-gray-50 dark:bg-slate-800 text-violet-600"
																: ""
														}`}
														data-accordion-target="#accordion-collapse-body-1"
														aria-expanded="true"
														aria-controls="accordion-collapse-body-1"
													>
														<span>{item.term}</span>
														<svg
															data-accordion-icon
															className="w-4 h-4 rotate-180 shrink-0"
															fill="currentColor"
															viewBox="0 0 20 20"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fillRule="evenodd"
																d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
																clipRule="evenodd"
															></path>
														</svg>
													</button>
												</h2>
												{state.activeIndex === index && (
													<div
														id="accordion-collapse-body-1"
														aria-labelledby="accordion-collapse-heading-1"
													>
														<div className="p-5">
															<p className="text-slate-400 dark:text-gray-400 mb-2">
																{item.desc}
															</p>
															<p className="text-slate-400 dark:text-gray-400">
																{item.definition}
															</p>
														</div>
													</div>
												)}
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
			<Switcher />
		</>
	);
}
