import React, { useState } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import image1 from "../../assets/images/new-images/Kripto-1.webp";
import Switcher from "../../components/switcher";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import { useTranslation } from "react-i18next";
import { whatisFx } from "../../data/EducationData";
import TopImage from "../../components/TopImage";
import { Helmet } from "react-helmet";

export default function WhyInvestCrypto() {
	const { t } = useTranslation();
	const [isOpen, setOpen] = useState(false);

	return (
		<>
			<Helmet>
				<title>{t("whyInvestCrypto")}</title>
				<meta
					name="What Is Crypto? Understanding the Foreign Exchange Market"
					content="Get a comprehensive overview of the crypto market and its fundamentals."
				/>
			</Helmet>
			<Navbar />
			<TopImage title={t("whyInvestCrypto")} />

			<section className="relative md:py-4">
				<div className="container">
					<div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 mb-8 pb-8 items-center">
						<div className="lg:col-span-5">
							<div className="relative">
								{/* <div className="absolute inset-0 bg-gradient-to-r from-red-600 to-violet-600 rounded-lg -mt-[15px] -ms-[15px] h-[98%] w-[98%] -z-1"></div> */}
								<img
									src={image1}
									className="rounded-lg shadow-md dark:shadow-gray-800 mt-3"
									alt=""
								/>
								<div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center"></div>
							</div>
						</div>

						<div className="lg:col-span-7  mt-10">
							<div className="lg:ms-5">
								<h3 class="md:text-4xl text-2xl md:leading-snug tracking-wide leading-snug font-semibold mt-2">
									{t("whyInvestTitle")}
								</h3>
								<h3 class="md:text-2xl text-2xl md:leading-snug tracking-wide leading-snug font-semibold mt-2">
									<span class="bg-gradient-to-r from-red-600 to-violet-600 text-transparent bg-clip-text">
										{t("whyInvestSubtitle")}
									</span>
									<span className="ms-2">{t("whyInvestColorSubtitle")}</span>
								</h3>
								<p className="mt-4">{t("whyInvestP1")}</p>
								<div className="text-slate-400 mt-6">
									<span className="text-white font-medium">
										{t("whyInvestItemTitle1")}
									</span>{" "}
									{t("whyInvestItemText1")}
								</div>
								<div className="text-slate-400 mt-6">
									<span className="text-white font-medium">
										{t("whyInvestItemTitle2")}
									</span>{" "}
									{t("whyInvestItemText2")}
								</div>
								<div className="text-slate-400 mt-6">
									<span className="text-white font-medium">
										{t("whyInvestItemTitle3")}
									</span>{" "}
									{t("whyInvestItemText3")}
								</div>
								<div className="text-slate-400 mt-6">
									<span className="text-white font-medium">
										{t("whyInvestItemTitle4")}
									</span>{" "}
									{t("whyInvestItemText4")}
								</div>
								<div className="text-slate-400 mt-6">
									<span className="text-white font-medium">
										{t("whyInvestItemTitle5")}
									</span>{" "}
									{t("whyInvestItemText5")}
								</div>
								<p className="mt-6">{t("whyInvestP2")}</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
			<Switcher />
		</>
	);
}
