import React, { useEffect } from "react";
import Creator from "../../components/creator";
import Collections from "../../components/collections";

import DiscoverItems from "../../components/discover-items";
import QA from "../../components/qa";
import GetTouch from "../../components/get-touch";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import { Link } from "react-router-dom";

import Switcher from "../../components/switcher";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, EffectFade } from "swiper";
import { Col, Container, Row } from "react-bootstrap";
// import { SliderOneData } from "../../data/sliderData";
import world from "../../assets/images/home/world.png";
import worldtr from "../../assets/images/home/world-tr.png";
import economic from "../../assets/images/home/economic-calender.png";
import aL from "../../assets/images/home/AL.png";
import awards from "../../assets/images/home/awards.png";
import WhyCloudex from "./components/whyCloudex";
import TraderPlatforms from "./components/traderPlatforms";
import JoinUs from "./components/joinUs";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import slider1 from "../../assets/images/slider/Slider-1.webp";
import slider2 from "../../assets/images/slider/Slider-2.webp";
import slider4 from "../../assets/images/slider/Slider-4.webp";
import slider5 from "../../assets/images/slider/Slider-5.webp";
import slider6 from "../../assets/images/slider/Slider-6.webp";

SwiperCore.use([Autoplay, Navigation, EffectFade]);

export default function IndexFour() {
	const { t } = useTranslation();
	useEffect(() => {
		document.documentElement.classList.add("dark");
		document.documentElement.classList.remove("light");
	}, []);

	useEffect(() => {
		console.log(
			"useEffect çalıştı, localStorage'dan dil anahtarı alındı:",
			localStorage.getItem("lang")
		);
	}, [localStorage.getItem("lang")]);

	const mainSlideOptions = {
		slidesPerView: 1,
		loop: true,
		effect: "none",
		navigation: {
			nextEl: "#main-slider-next",
			prevEl: "#main-slider-prev",
		},
		autoplay: {
			delay: 5000000000,
		},
	};

	const SliderOneData = [
		{
			image: slider1,
			subTitle: t("cloudexInsuranceText"),
			title: t("cloudexInsuranceSlider"),
			button: {
				label: true,
				url: "/cloudex-insurance",
			},
		},
		{
			image: slider4,
			subTitle: t("whyCloudexText"),
			title: t("whyCloudex"),
			button: {
				label: true,
				url: "/why-cloudex",
			},
		},
		{
			image: slider5,
			subTitle: t("whatIsCryptoText"),
			title: t("whatIsCrypto"),
			button: {
				label: false,
				url: "/why-cloudex",
			},
		},
		{
			image: slider6,
			subTitle: t("whyCryptoText"),
			title: t("whyCrypto"),
			button: {
				label: false,
				url: "/why-cloudex",
			},
		},
		{
			image: slider2,
			subTitle: t("cloudexAwardsText"),
			title: t("cloudexAwards"),
			button: {
				label: true,
				url: "/awards",
			},
		},
	];

	return (
		<>
			<Helmet>
				<title>Cloudex Crypto</title>
				<meta
					name="Cloudex - Your Crypto Trading platform"
					content="Cloudex offers cutting-edge crypto trading solutions. Join us for a seamless trading experience."
				/>
			</Helmet>

			<Navbar />

			<div className="main-slider">
				<Swiper {...mainSlideOptions} loop={true}>
					{SliderOneData.map(({ image, subTitle, title, button }, index) => (
						<SwiperSlide key={index}>
							<div
								className="image-layer object-scale-down mobile-slider-image"
								style={{ backgroundImage: `url(${image})` }}
							></div>
							<Container>
								<Row>
									<Col lg={12} className="text-left">
										<h3 className="main-slider__title">{title}</h3>
										<p className="main-slider__subtext">{subTitle}</p>
										{button.label && (
											<a href={button.url}>
												<a
													className={`common_btn btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white`}
												>
													<span>{t("discover")}</span>
												</a>
											</a>
										)}
									</Col>
								</Row>
							</Container>
						</SwiperSlide>
					))}
					<div className="swiper-button-prev" id="main-slider-prev">
						<i className="fa fa-angle-left"></i>
					</div>
					<div className="swiper-button-next" id="main-slider-next">
						<i className="fa fa-angle-right"></i>
					</div>
				</Swiper>
			</div>

			<WhyCloudex />

			<div className="scrolling-image-container">
				<img
					src={t("world").replace("[env]", window.location.origin)}
					alt="line_economic"
					className="scrolling-image"
				/>
			</div>

			<TraderPlatforms />

			<Link to="/awards" className="flex items-center justify-center mt-12">
				<img src={awards} alt="awards" className="" width="70%" />
			</Link>

			<JoinUs />

			{/* <div className="scrolling-image-container">
				<img src={economic} alt="economic" className="scrolling-image" />
			</div>

			<Link to="/ai-trading">
				<img src={aL} alt="aL" className="" />
			</Link> */}

			<Footer />

			<Switcher />
		</>
	);
}
