import React, { useState } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import about2 from "../../assets/images/800x600-whycloudex.png";
import Switcher from "../../components/switcher";
import { Hexagon } from "react-feather";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import { useTranslation } from "react-i18next";
import TopImage from "../../components/TopImage";
import { Helmet } from "react-helmet";
import JoinUs from "../index/components/joinUs";

import deposit from '../../assets/images/new-images/cuzdan5.webp'
import withdrawal from '../../assets/images/new-images/cuzdan2.webp'

export default function WhyCloudex() {
	const [isOpen, setOpen] = useState(false);
	const { t } = useTranslation();

	const datas = [
		{
			icon: "uil uil-bright",
			title: t("whyTitle1"),
			description: t("whyText1"),
			link: "/why-cloudex",
		},
		{
			icon: "uil uil-rss-interface",
			title: t("whyTitle2"),
			description: t("whyText2"),
			link: "/why-cloudex",
		},
		{
			icon: "uil uil-coins",
			title: t("whyTitle3"),
			description: t("whyText3"),
			link: "/why-cloudex",
		},
		{
			icon: "uil uil-money-withdrawal",
			title: t("whyTitle4"),
			description: t("whyText4"),
			link: "/why-cloudex",
		},
		{
			icon: "uil uil-lock-alt",
			title: t("whyTitle5"),
			description: t("whyText5"),
			link: "/why-cloudex",
		},
		{
			icon: "uil uil-cloud-question",
			title: t("whyTitle6"),
			description: t("whyText6"),
			link: "/why-cloudex",
		},
	];

	const aiDatas = [
		{
			icon: "uil uil-android-alt",
			title: t("aiTitle1"),
			description: t("aiText1"),
		},
		{
			icon: "uil uil-asterisk",
			title: t("aiTitle2"),
			description: t("aiText2"),
		},
		{
			icon: "uil uil-analytics",
			title: t("aiTitle3"),
			description: t("aiText3"),
		},
		{
			icon: "uil uil-tachometer-fast",
			title: t("aiTitle4"),
			description: t("aiText4"),
		},
		{
			icon: "uil uil-rss-interface",
			title: t("aiTitle5"),
			description: t("aiText5"),
		},
	];

	return (
		<>
			<Helmet>
				<title>{t("whyChooseCloudex")}</title>
				<meta
					name="Why Cloudex? Discover the Benefits of Our Crypto Trading Platform"
					content="Explore the advantages of trading with CloudEx and take your crypto trading to the next level."
				/>
			</Helmet>

			<Navbar />
			<TopImage title={"whyChooseCloudex"} />

			{/* <WhyCloudexCards /> */}

			<section className="relative md:py-24 py-16">
				<div className="container">
					<div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
						<div className="lg:col-span-12" style={{marginBottom: "5rem"}}>
							<h1 className="text-violet-600 font-medium mb-8 md:text-xxl text-4xl md:leading-snug leading-snug font-semibold">
								{t("whyChooseCloudex")}
							</h1>

							<p className="text-xl font-semibold mb-8 text-slate-400">
								{t("whyText7")}
							</p>

							{datas.map((e) => (
								<div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mb-6">
									<div className="md:col-span-2">
										<div className="relative overflow-hidden text-transparent -m-3">
											<Hexagon className="h-28 w-28 fill-violet-600/5 mx-auto rotate-[30deg]"></Hexagon>
											<div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-5xl flex align-middle justify-center items-center">
												<i className={e.icon}></i>
											</div>
										</div>
									</div>
									<div className="md:col-span-10">
										<h5 className="text-xl font-semibold mb-4 mt-8">
											{e.title}
										</h5>
										<p className="text-slate-400">{e.description}</p>
									</div>
								</div>
							))}
						</div>
						<div className="lg:col-span-7" style={{marginBottom: "5rem"}}>
							<h2 className="text-violet-600 font-medium mb-4 mt-12 md:text-xxl text-4xl md:leading-snug leading-snug font-semibold">
								{t("depositandwithdrawal")}
							</h2>
							{/* <h5 className="text-xl font-semibold mb-4 mt-8 ">
								{t("depositandwithdrawalsubtitle")}
							</h5> */}
							<p className="text-slate-400">
								{t("depositandwithdrawalsubtext")}
							</p>
							<ul className="list-none text-slate-400 mt-3">
								<li className="mt-2">
									<div>
										<h5 className="text-xl font-semibold mb-4 mt-4">
											{t("deposit")}{" "}
										</h5>
										{t("depositText")}
									</div>
								</li>
								<li className="mt-4">
									<div>
										<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
										<span className="font-bold">{t("bankTransfer")} </span>
									</div>
									<div>{t("bankTransferText")}</div>
								</li>
								<li className="mt-4">
									<div>
										<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
										<span className="font-bold">{t("creditCard")}</span>
									</div>
									<div>{t("creditCardText")}</div>
								</li>
								<li className="mt-4">
									<div>
										<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
										<span className="font-bold">
											{t("cryptocurrencyTransfer")}
										</span>
									</div>
									<div>{t("cryptocurrencyTransferText")}</div>
								</li>
							</ul>
						</div>
						<div className="lg:col-span-5" style={{marginBottom: "5rem"}}>
							<div className="relative">
								<img
									src={deposit}
									// className="rounded-lg shadow-md dark:shadow-gray-800"
                                    className="rounded-lg"
									alt=""
								/>
								<div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center"></div>
							</div>
						</div>
						<div className="lg:col-span-5" style={{marginBottom: "5rem"}}>
							<div className="relative">
								<img
									src={withdrawal}
									// className="rounded-lg shadow-md dark:shadow-gray-800"
                                    className="rounded-lg"
									alt=""
								/>
								<div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center"></div>
							</div>
						</div>
						<div className="lg:col-span-7" style={{marginBottom: "5rem"}}>
							<ul className="list-none text-slate-400 mt-3">
								<li className="mt-2">
									<div>
										<h5 className="text-xl font-semibold mb-4 mt-4">
											{t("withdrawal")}{" "}
										</h5>
										{t("withdrawalText")}
									</div>
								</li>
								<li className="mt-4">
									<div>
										<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
										<span className="font-bold">{t("bankTransfer")} </span>
									</div>
									<div>{t("withdrawalBankTransferText")}</div>
								</li>
								<li className="mt-4">
									<div>
										<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
										<span className="font-bold">
											{t("cryptocurrencyTransfer")}
										</span>
									</div>
									<div>{t("withdrawalCryptocurrencyTransferText")}</div>
								</li>
							</ul>
							<p className="text-slate-400 mt-6">
								{t("depositandwithdrawalsummtext")}
							</p>
						</div>
						<div className="lg:col-span-12">
							<p className="text-xl font-semibold mb-8 text-slate-400">
								{t("aiSubtitle")}
							</p>

							{aiDatas.map((e) => (
								<div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mb-6">
									<div className="md:col-span-2">
										<div className="relative overflow-hidden text-transparent -m-3">
											<Hexagon className="h-28 w-28 fill-violet-600/5 mx-auto rotate-[30deg]"></Hexagon>
											<div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-5xl flex align-middle justify-center items-center">
												<i className={e.icon}></i>
											</div>
										</div>
									</div>
									<div className="md:col-span-10">
										<h5 className="text-xl font-semibold mb-4 mt-8">
											{e.title}
										</h5>
										<p className="text-slate-400">{e.description}</p>
									</div>
								</div>
							))}
						</div>
						<div className="lg:col-span-12">
							<p className="text-xl font-semibold text-slate-400">
								{t("aiSubtext")}
							</p>
						</div>
					</div>
				</div>
			</section>
			<div className="mb-8">
				<JoinUs />
			</div>
			<Footer />
			<Switcher />
		</>
	);
}
