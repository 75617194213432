import React, { useState } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import image1 from "../assets/images/visionMision/1.png";
import Switcher from "../components/switcher";
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import { useTranslation } from "react-i18next";
import TopImage from "../components/TopImage";
import { Helmet } from "react-helmet";
import { Hexagon } from "react-feather";

export default function References() {
	const { t } = useTranslation();

	const datas = [
		{
			step: "1",
			description: t("firstStepText"),
			link: "/why-cloudex",
		},
		{
			step: "2",
			description: t("whyText2"),
			link: "/why-cloudex",
		},
		{
			step: "3",
			description: t("whyText3"),
			link: "/why-cloudex",
		},
	];

	return (
		<>
			<Helmet>
				<title>{t("referralProgram")}</title>
				<meta
					name="Our Vision and Mission - Guiding Principles at CloudEx"
					content="Explore the vision and mission that drive us at CloudEx in
                    providing exceptional Crypto trading solutions."
				/>
			</Helmet>
			<Navbar />
			<TopImage title={t("referralProgram")} />
			<section className="relative md:py-24 py-16">
				<div className="container">
					<div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
						<div className="lg:col-span-12">
							{datas.map((e) => (
								<div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mb-6">
									<div className="md:col-span-2">
										<div className="relative overflow-hidden text-transparent -m-3">
											<Hexagon className="h-28 w-28 fill-violet-600/5 mx-auto rotate-[30deg]"></Hexagon>
											<div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-5xl flex align-middle justify-center items-center">
												{e.step}
											</div>
										</div>
									</div>
									<div className="md:col-span-10">
										<p className="text-slate-400 text-lg font-medium">
											{e.description}
										</p>
									</div>
								</div>
							))}
							<div className="mt-16">
								<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
								<span className="font-medium text-slate-400 text-lg">
									{t("referralp1")}{" "}
								</span>
							</div>
							<div className="mt-4">
								<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
								<span className="font-medium text-slate-400 text-lg">
									{t("referralp2")}{" "}
								</span>
							</div>
						</div>
						{/* <div className="lg:col-span-7">
							<ul className="references mt-24">
								<li className="shadow" style={{ background: "#100D28" }}>
									<div className="date">{t("firstStep")}</div>
									<div className="title">{t("firstStepText")}</div>
								</li>
								<li className="shadow" style={{ background: "#100D28" }}>
									<div className="date">{t("secondStep")}</div>
									<div className="title">{t("secondStepText")}</div>
								</li>
								<li className="shadow" style={{ background: "#100D28" }}>
									<div className="date">{t("thirdStep")}</div>
									<div className="title">{t("thirdStepText")}</div>
								</li>
							</ul>
						</div>
						<div className="lg:col-span-5 lg:ms-5">
							<div>
								<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
								<span className="font-bold text-slate-400">{t("referralp1")} </span>
							</div>
							<div className="mt-4">
								<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
								<span className="font-bold text-slate-400">{t("referralp2")} </span>
							</div>
						</div> */}
					</div>
				</div>
			</section>
			<Footer />
			<Switcher />
		</>
	);
}
