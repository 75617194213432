import React from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Switcher from "../components/switcher";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TopImage from "../components/TopImage";
import { Helmet } from "react-helmet";

export default function KVKK() {
	const { t } = useTranslation();
	return (
		<>
			<Helmet>
				<title>{t("kvkk")}</title>
				<meta
					name="GDPR Compliance - Your Data Rights at Cloudex"
					content="Learn about GDPR compliance and your data rights when
                    using Cloudex's services."
				/>
			</Helmet>
			<Navbar />
			<TopImage title={"kvkk"} />
			<section className="relative md:py-24 py-16">
				<div className="container">
					<div className="md:flex justify-center">
						<div className="md:w-3/4">
							<div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
								<h5 className="text-xl font-semibold mb-4">{t("kvkk")}</h5>
								<>
									<h5 className="text-large font-semibold mb-4 mt-4 text-violet-600">
										1. {t("dataController")}
									</h5>
									<p className="text-slate-400">{t("dataControllerText")}</p>
								</>
								<>
									<h5 className="text-large font-semibold mb-4 text-violet-600 mt-4">
										2. {t("purposesLegalGrounds")}
									</h5>
									<p className="text-slate-400">
										{t("purposesLegalGroundsText")}
									</p>
									<ul className="list-none text-slate-400 mt-3">
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("purposesLegalGroundsItem1")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("purposesLegalGroundsItem2")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("purposesLegalGroundsItem3")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("purposesLegalGroundsItem4")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("purposesLegalGroundsItem5")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("purposesLegalGroundsItem6")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("purposesLegalGroundsItem7")}</div>
										</li>
									</ul>
									<p className="text-slate-400">{t("purposesLegalGroundsP")}</p>
								</>
								<>
									<h5 className="text-large font-semibold mb-4 text-violet-600 mt-4">
										3. {t("MethodsCollectingData")}
									</h5>
									<p className="text-slate-400">
										{t("MethodsCollectingDataText")}
									</p>
									<ul className="list-none text-slate-400 mt-3">
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("MethodsCollectingDataItem1")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("MethodsCollectingDataItem2")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("MethodsCollectingDataItem3")}</div>
										</li>
									</ul>
								</>
								<>
									<h5 className="text-large font-semibold mb-4 text-violet-600 mt-4">
										4. {t("sharingPersonalData")}
									</h5>
									<p className="text-slate-400">
										{t("sharingPersonalDataText")}
									</p>
									<ul className="list-none text-slate-400 mt-3">
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("sharingPersonalDataItem1")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("sharingPersonalDataItem2")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("sharingPersonalDataItem3")}</div>
										</li>
									</ul>
								</>
								<>
									<h5 className="text-large font-semibold mb-4 text-violet-600 mt-4">
										5. {t("retentionPeriod")}
									</h5>
									<p className="text-slate-400">{t("retentionPeriodText")}</p>
								</>
								<>
									<h5 className="text-large font-semibold mb-4 text-violet-600 mt-4">
										6. {t("rightsDataSubjects")}
									</h5>
									<p className="text-slate-400">
										{t("rightsDataSubjectsText")}
									</p>
									<ul className="list-none text-slate-400 mt-3">
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("rightsDataSubjectItem1")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("rightsDataSubjectItem2")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("rightsDataSubjectItem3")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("rightsDataSubjectItem4")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("rightsDataSubjectItem5")}</div>
										</li>
									</ul>
									<p className="text-slate-400">{t("rightsDataSubjectP")}</p>
								</>
								<>
									<h5 className="text-large font-semibold mb-4 text-violet-600 mt-4">
										7. {t("changes")}
									</h5>
									<p className="text-slate-400">{t("changesP1")}</p>
									<p className="text-slate-400">{t("changesP2")}</p>
								</>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
			<Switcher />
		</>
	);
}
