import React from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Switcher from "../components/switcher";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TopImage from "../components/TopImage";
import { Helmet } from "react-helmet";

export default function PrivacyPolicy() {
	const { t } = useTranslation();
	return (
		<>
			<Helmet>
				<title>{t("privacyPolicy")}</title>
				<meta
					name="Privacy Policy - Your Data Security at Cloudex"
					content="Learn about how Cloudex protects your data and your
                    privacy with our comprehensive privacy policy."
				/>
			</Helmet>
			<Navbar />
			<TopImage title={"privacyPolicy"} />

			<section className="relative md:py-24 py-16">
				<div className="container">
					<div className="md:flex justify-center">
						<div className="md:w-3/4">
							<div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
								<>
									<h5 className="text-large font-semibold mb-4 mt-4 text-violet-600">
										1. {t("introduction")}
									</h5>
									<p className="text-slate-400">{t("introductionText")}</p>
								</>
								<>
									<h5 className="text-large font-semibold mb-4 text-violet-600 mt-4">
										2. {t("informationWeCollect")}
									</h5>
									<p className="text-slate-400">
										{t("informationWeCollectText")}
									</p>
									<ul className="list-none text-slate-400 mt-3">
										<li className="mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
												<span className="font-bold">
													{t("informationWeCollectItemTitle1")}{" "}
												</span>
											</div>
											<div> {t("informationWeCollectItemText1")}</div>
										</li>
										<li className="mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
												<span className="font-bold">
													{t("informationWeCollectItemTitle2")}
												</span>
											</div>
											<div>{t("informationWeCollectItemText2")}</div>
										</li>
										<li className="mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
												<span className="font-bold">
													{t("informationWeCollectItemTitle3")}
												</span>
											</div>
											<div>{t("informationWeCollectItemText3")}</div>
										</li>
										<li className=" mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
												<span className="font-bold">{t("cookies")} </span>
											</div>
											<div>{t("informationWeCollectItemText4")}</div>
										</li>
									</ul>
								</>
								<>
									<h5 className="text-large font-semibold mb-4 text-violet-600 mt-4">
										3. {t("howWeUseYourInfo")}
									</h5>
									<p className="text-slate-400">{t("howWeUseInfoText")}</p>
									<ul className="list-none text-slate-400 mt-3">
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("howWeUseInfoItem1")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("howWeUseInfoItem2")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("howWeUseInfoItem3")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("howWeUseInfoItem4")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("howWeUseInfoItem5")}</div>
										</li>
									</ul>
								</>
								<>
									<h5 className="text-large font-semibold mb-4 text-violet-600 mt-4">
										4. {t("informationSharing")}
									</h5>
									<p className="text-slate-400">
										{t("informationSharingText")}
									</p>
									<ul className="list-none text-slate-400 mt-3">
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("informationSharingItem1")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("informationSharingItem2")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("informationSharingItem3")}</div>
										</li>
									</ul>
								</>
								<>
									<h5 className="text-large font-semibold mb-4 text-violet-600 mt-4">
										5. {t("dataSecurity")}
									</h5>
									<p className="text-slate-400">{t("dataSecurityText")}</p>
								</>
								<>
									<h5 className="text-large font-semibold mb-4 text-violet-600 mt-4">
										6. {t("yourRights")}
									</h5>
									<p className="text-slate-400">{t("yourRightsText")}</p>
									<ul className="list-none text-slate-400 mt-3">
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("yourRightsItem1")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("yourRightsItem2")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("yourRightsItem3")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("yourRightsItem4")}</div>
										</li>
										<li className="flex mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
											</div>
											<div> {t("yourRightsItem5")}</div>
										</li>
									</ul>
									<p className="text-slate-400">{t("yourRightsP1")}</p>
								</>
								<>
									<h5 className="text-large font-semibold mb-4 text-violet-600 mt-4">
										7. {t("changestothisPolicy")}
									</h5>
									<p className="text-slate-400">
										{t("changestothisPolicyText")}
									</p>
								</>
								<>
									<h5 className="text-large font-semibold mb-4 text-violet-600 mt-4">
										8. {t("contact")}
									</h5>
									<p className="text-slate-400">{t("contactUsText")}</p>
								</>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
			<Switcher />
		</>
	);
}
