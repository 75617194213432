import React from "react";
import logo_white from "../assets/images/logo/logo-png.png";
import trusted_logo from "../assets/images/logo/trustpilot-logo.png";
import app from "../assets/images/app.png";
import playstore from "../assets/images/playstore.png";
import { Link } from "react-router-dom";
import { Mail, Phone, MapPin } from "react-feather";
import { useTranslation } from "react-i18next";

export default function Footer() {
	const { t } = useTranslation();
	return (
		<footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
			<div className="container">
				<div className="grid grid-cols-1">
					<div className="relative py-16">
						<div className="relative w-full">
							{/* <div className="relative -top-40 bg-white dark:bg-slate-900 lg:px-8 px-6 py-10 rounded-xl shadow dark:shadow-gray-800 overflow-hidden">
                                <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                                    <div className="md:text-start text-center z-1">
                                        <h3 className="text-[26px] font-semibold text-slate-900 dark:text-white">Subscribe to Newsletter!</h3>
                                        <p className="text-slate-400 max-w-xl mx-auto">Subscribe to get latest updates and information.</p>
                                    </div>

                                    <div className="subcribe-form z-1">
                                        <form className="relative max-w-lg md:ms-auto">
                                            <input type="email" id="subcribe" name="email" className="pt-4 pe-40 pb-4 ps-6 w-full h-[50px] outline-none text-slate-900 dark:text-white rounded-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-800" placeholder="Enter your email :" />
                                            <button type="submit" className="btn absolute top-[2px] end-[3px] h-[46px] bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full">Subscribe</button>
                                        </form>
                                    </div>
                                </div>

                                <div className="absolute -top-5 -start-5">
                                    <div className="uil uil-envelope lg:text-[150px] text-7xl text-slate-900/5 dark:text-white/5 -rotate-45"></div>
                                </div>

                                <div className="absolute -bottom-5 -end-5">
                                    <div className="uil uil-pen lg:text-[150px] text-7xl text-slate-900/5 dark:text-white/5"></div>
                                </div>
                            </div> */}

							<div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
								<div className="lg:col-span-4 md:col-span-12">
									<Link to="#" className="text-[22px] focus:outline-none">
										<img
											src={logo_white}
											alt=""
											className="image-footer"
											width="150px"
										/>
									</Link>
									<p className="mt-6 text-gray-300">{t("footerText")}</p>
									<img
										src={trusted_logo}
										alt=""
										className="image-footer mt-10"
									/>
								</div>

								<div className="lg:col-span-2 md:col-span-4">
									<h5 className="tracking-[1px] text-lg text-gray-100 font-semibold">
										{t("Cloudex")}
									</h5>
									<ul className="list-none footer-list mt-6">
										<li className="mt-[10px]">
											<Link
												to="/"
												className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
											>
												<i className="uil uil-angle-right-b me-1"></i>
												{t("home")}
											</Link>
										</li>
										<li className="mt-[10px]">
											<Link
												to="/about-ceo"
												className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
											>
												<i className="uil uil-angle-right-b me-1"></i>{" "}
												{t("aboutUs")}
											</Link>
										</li>
										<li className="mt-[10px]">
											<Link
												to="/why-invest-cryptocurrencies"
												className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
											>
												<i className="uil uil-angle-right-b me-1"></i>{" "}
												{t("academy")}
											</Link>
										</li>
										<li className="mt-[10px]">
											<Link
												to="/announcements"
												className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
											>
												<i className="uil uil-angle-right-b me-1"></i>{" "}
												{t("announcements")}
											</Link>
										</li>
										<li className="mt-[10px]">
											<Link
												to="/news"
												className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
											>
												<i className="uil uil-angle-right-b me-1"></i>{" "}
												{t("news")}
											</Link>
										</li>
									</ul>
								</div>

								<div className="lg:col-span-3 md:col-span-4">
									<h5 className="tracking-[1px] text-lg text-gray-100 font-semibold">
										{t("accountTransactions")}
									</h5>
									<ul className="list-none footer-list mt-4">
										<li className="mt-[10px]">
											<Link
												to="#"
												className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
											>
												<i className="uil uil-angle-right-b me-1"></i>
												{t("login")}
											</Link>
										</li>
										<li className="mt-[10px]">
											<Link
												to="/account"
												className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
											>
												<i className="uil uil-angle-right-b me-1"></i>
												{t("signup")}
											</Link>
										</li>
									</ul>
									<h5 className="tracking-[1px] text-lg text-gray-100 font-semibold mt-4">
										{t("pages")}
									</h5>
									<ul className="list-none footer-list mt-4">
										<li>
											<Link
												to="/privacy-policy"
												className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
											>
												<i className="uil uil-angle-right-b me-1"></i>
												{t("privacyPolicy")}
											</Link>
										</li>
										<li className="mt-[10px]">
											<Link
												to="/cookie-policy"
												className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
											>
												<i className="uil uil-angle-right-b me-1"></i>
												{t("cookiePolicy")}
											</Link>
										</li>
										<li className="mt-[10px]">
											<Link
												to="/gdpr"
												className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
											>
												<i className="uil uil-angle-right-b me-1"></i>
												{t("gdpr")}
											</Link>
										</li>
									</ul>
								</div>

								<div className="lg:col-span-3 md:col-span-4">
									{/* <h5 className="tracking-[1px] text-lg text-gray-100 font-semibold">Download the Giglink app</h5>

                                    <ul className="list-none mt-6">
                                        <li className="inline"><img src={app} className="h-9 inline-block" alt="" /></li>
                                        <li className="inline"><img src={playstore} className="h-9 inline-block" alt="" /></li>
                                    </ul> */}

									<div className="mt-6">
										<h5 className="tracking-[1px] text-lg text-gray-100 font-semibold">
											{t("contact")}
										</h5>

										<div className="flex mt-6">
											<Mail className="w-5 h-5 text-violet-600 me-3 mt-1"></Mail>
											<div className="">
												<Link
													to="mailto:support@cloudexcrypto.com"
													className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
												>
													support@cloudexcrypto.com
												</Link>
											</div>
										</div>

										<div className="flex mt-6">
											<Phone className="w-5 h-5 text-violet-600 me-3 mt-1"></Phone>
											<div className="">
												<Link
													to="https://wa.me/447438488658"
													target="_blank"
													className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
												>
													+44 7438 488658
												</Link>
											</div>
										</div>
										<div className="flex mt-6">
											<MapPin className="w-5 h-5 text-violet-600 me-3 mt-1"></MapPin>
											<div className="">
												<Link
													to="https://maps.app.goo.gl/w3eokxPVX56QaQn37"
													target="_blank"
													className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
												>
													{t("address")}
												</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="py-[30px] px-0 border-t border-gray-800 dark:border-gray-700">
				<div className="container text-center">
					<div className="grid md:grid-cols-2 items-center gap-6">
						<div className="md:text-start text-center">
							<p className="mb-0 text-gray-300">
								{new Date().getFullYear()} {t("copyright")}
							</p>
						</div>

						<ul className="list-none md:text-end text-center">
							<li className="inline">
								<Link
									to="https://www.instagram.com/cloudexcrypto"
									target="_blank"
									className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
								>
									<i
										className="uil uil-instagram align-middle"
										title="instagram"
									></i>
								</Link>
							</li>
							<li className="inline">
								<Link
									to="https://www.facebook.com/cloudexcrypto"
									target="_blank"
									className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
								>
									<i
										className="uil uil-facebook-f align-middle"
										title="facebook"
									></i>
								</Link>
							</li>
							<li className="inline">
								<Link
									to="https://x.com/cloudexcrypto"
									target="_blank"
									className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
								>
									<i
										className="fa-brands fa-x-twitter align-middle"
										title="X"
									></i>
								</Link>
							</li>
							<li className="inline">
								<Link
									to="https://www.threads.net/@cloudexcrypto"
									target="_blank"
									className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
								>
									<i
										class="fa-brands fa-threads align-middle"
										title="threads"
									></i>
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	);
}
