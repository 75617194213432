import React, { useState } from "react";
import Navbar from "../../components/navbar";
import Team from "../../components/team";
import Blog from "../../components/blog";
import Footer from "../../components/footer";
import about from "../../assets/images/about.jpg";
import Fortuna from "../../assets/images/whyCloudex/fortuna.png";
import Img2 from "../../assets/images/whyCloudex/cyber-security-concept-digital-art 1-min.png"
import Switcher from "../../components/switcher";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import { useTranslation } from "react-i18next";
import TopImage from "../../components/TopImage";
import insurance from "../../assets/images/aboutus/Insurance.jpg";
import { Helmet } from "react-helmet";
import { Hexagon } from "react-feather";

export default function CloudexInsurance() {
	const [isOpen, setOpen] = useState(false);
	const { t } = useTranslation();

	const datas = [
		{
			// icon: "uil uil-bright",
			title: t("insurance_item_title1"),
			description: t("insurance_item_text1"),
		},
		{
			// icon: "uil uil-rss-interface",
			title: t("insurance_item_title2"),
			description: t("insurance_item_text2"),
		},
		{
			// icon: "uil uil-coins",
			title: t("insurance_item_title3"),
			description: t("insurance_item_text3"),
		},
		{
			// icon: "uil uil-money-withdrawal",
			title: t("insurance_item_title4"),
			description: t("insurance_item_text4"),
		},
		{
			// icon: "uil uil-lock-alt",
			title: t("insurance_item_title5"),
			description: t("insurance_item_text5"),
		}
	];
	const datas2 = [
		{
			// icon: "uil uil-bright",
			title: t("insurance_item2_title1"),
			description: t("insurance_item_text1"),
		},
		{
			// icon: "uil uil-rss-interface",
			title: t("insurance_item2_title2"),
			description: t("insurance_item_text2"),
		},
		{
			// icon: "uil uil-coins",
			title: t("insurance_item2_title3"),
			description: t("insurance_item_text3"),
		},
	];

	return (
		<>
			<Helmet>
				<title>{t("cloudexInsurance")}</title>
				<meta
					name="Cloudex Insurance - Protecting Your Crypto Investments"
					content="Learn about our insurance options that safeguard your crypto investments with Cloudex."
				/>
			</Helmet>
			<Navbar />
			<TopImage title={"cloudexInsurance"} />

			<section className="relative md:py-24 py-16">
				<div className="container">
					<div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
						<div className="lg:col-span-7">
							{/* <div className="lg:ms-5">
								<p className="text-slate-400">{t("cloudexInsurP1")}</p>
								<p className="text-slate-400 mt-4">{t("cloudexInsurP2")}</p>
								<p className="text-slate-400 mt-4">{t("cloudexInsurP3")}</p>
								<p className="text-slate-400 mt-4">{t("cloudexInsurP4")}</p>
								<p className="text-slate-400 mt-4">{t("cloudexInsurP5")}</p>
							</div> */}
							<div className="lg:col-span-12" style={{ marginBottom: "5rem" }}>
								<h1 className="text-violet-600 font-medium mb-8 md:text-xxl text-4xl md:leading-snug leading-snug font-semibold">
									{t("insurace_title")}
								</h1>
								<h5 className="text-xl font-semibold mb-4 mt-4">
									{t("insurace_paragraph1")}
								</h5>
								<p className="text-l font-semibold mb-8 text-slate-400">
									{t("insurace_paragraph2")}
								</p>

								<h1 className="text-violet-600 font-medium mb-8 mt-10 md:text-xxl text-4xl md:leading-snug leading-snug font-semibold">
									{t("insurance_whycloudex")}
								</h1>

								{datas.map((e) => (
									<div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mb-8">
										<div className="md:col-span-12">
											<h5 className="text-xl font-semibold mb-5">
												{e.title}
											</h5>
											<p className="text-slate-400">{e.description}</p>
										</div>
									</div>
								))}
							</div>
						</div>
						<div className="lg:col-span-5">
							<div className="m-10">
								{/* <div className="absolute inset-0 bg-gradient-to-r from-red-600 to-violet-600 rounded-lg -mt-[15px] -ms-[15px] h-[98%] w-[98%] -z-1"></div> */}
								<img
									src={Img2}
									className="rounded-lg shadow-md dark:shadow-gray-800"
									alt=""
								/>
							</div>
						</div>
					</div>

					<div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px] mt-10">
						<div className="lg:col-span-6">
							<div className="m-10">
								{/* <div className="absolute inset-0 bg-gradient-to-r from-red-600 to-violet-600 rounded-lg -mt-[15px] -ms-[15px] h-[98%] w-[98%] -z-1"></div> */}
								<img
									src={insurance}
									className="rounded-lg shadow-md dark:shadow-gray-800"
									alt=""
									width="80%"
								/>
							</div>
						</div>
						<div className="lg:col-span-6">
							<div className="lg:col-span-12" style={{ marginBottom: "5rem" }}>
								<h1 className="text-violet-600 font-medium mb-8 md:text-xxl text-4xl md:leading-snug leading-snug font-semibold">
									{t("insurance_title2")}
								</h1>

								{datas2.map((e) => (
									<div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mb-8">
										<div className="md:col-span-12">
											<h5 className="text-xl font-semibold mb-5">
												{e.title}
											</h5>
											<p className="text-slate-400">{e.description}</p>
										</div>
									</div>
								))}
								<p className="text-xl font-semibold mb-8 mt-10">
									{t("insurace_paragraph3")}
								</p>
								<div className="mt-10 w-100">
									<a href="https://fortunaprotect.com/" target="_blank">
										<img src={Fortuna} alt="fortuna-protect" width="275px" className="mx-auto" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
			<Switcher />
		</>
	);
}
