import React from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import image1 from "../../assets/images/new-images/ai.webp";
import TopImage from "../../components/TopImage";
import { Helmet } from "react-helmet";

export default function AiTrading() {
	const { t } = useTranslation();
	return (
		<>
			<Helmet>
				<title>AI Trading</title>
				<meta
					name="AI Trading - Harness the Power of Artificial Intelligence in Crypto"
					content="Explore AI-powered trading solutions at CloudEx and enhance your crypto trading strategies."
				/>
			</Helmet>
			<Navbar />
			<TopImage title={"AI Trading"} />

			<section className="relative md:py-24 py-16">
				<div className="container">
					<div className="md:flex justify-center">
						<div className="md:w-3/4">
							<div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
								<img src={image1} className="rounded-lg mt-4" alt="" />
								<h5 className="text-xl font-semibold mb-4 mt-10">
									{t("aiTradingTitle1")}
								</h5>
								<p className="text-slate-400 mb-4">{t("aiTradingText1")}</p>

								<h5 className="text-xl font-semibold mb-4">
									{t("aiTradingTitle2")}
								</h5>
								<p className="text-slate-400 mb-4">{t("aiTradingText2")}</p>

								<h5 className="text-xl font-semibold mb-4">
									{t("aiTradingTitle3")}
								</h5>
								<p className="text-slate-400 mb-4">{t("aiTradingText3")}</p>

								<h5 className="text-xl font-semibold mb-4">
									{t("aiTradingTitle4")}
								</h5>
								<p className="text-slate-400 mb-4">{t("aiTradingText4")}</p>

								<h5 className="text-xl font-semibold mb-4">
									{t("aiTradingTitle5")}
								</h5>
								<p className="text-slate-400 mb-4">{t("aiTradingText5")}</p>

								<h5 className="text-xl font-semibold mb-4">
									{t("aiTradingTitle6")}
								</h5>
								<p className="text-slate-400 mb-4">{t("aiTradingText6")}</p>

								{/* <div className="mt-8">
                                    <a href="/" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full">Print</a>
                                </div> */}
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
			<Switcher />
		</>
	);
}
