import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";
import InputMask from 'react-input-mask';
import moment from "moment";

const LoginSchema = Yup.object().shape({
	name: Yup.string().required("Lütfen İsminizi Giriniz"),
	surname: Yup.string().required("Lütfen Soyisminizi Giriniz"),
	email: Yup.string()
		.email("Lütfen Geçerli Bir Email Adresi Giriniz")
		.required("Lütfen Mail Adresinizi Giriniz"),
	phone: Yup.string()
		.required("Lütfen Telefon Numaranızı Giriniz.")
		.test(
			"starts-with-5",
			"Telefon numarası 5 ile başlamalıdır",
			(value) => value && value[1] === "5"
		),
	password: Yup.string()
		.required("Lütfen Parolanızı Giriniz.")
		.min(6, "Parolanız Minimum 6 Karakter Olmalıdır"),
	repassword: Yup.string().oneOf(
		[Yup.ref("password"), null],
		"Parolalar Eşleşmiyor!"
	),
	identity: Yup.string().matches(/^[0-9]+$/, "Lütfen Sadece Sayı Giriniz."),
	dateofbirth: Yup.string().required("Lütfen Doğum Tarihinizi Giriniz.").test(
		"valid-date",
		"Lütfen Doğum Tarihinizi Giriniz",
		(value) => moment(value, "DD/MM/YYYY", true).isValid()
	),
});

function CreateAccount() {
	const { t } = useTranslation();
	const [refcode, setRefcode] = useState(window.location.search)
	const [startDate, setStartDate] = useState(new Date());
	const [show, setShow] = useState(false);

	const phoneNumberMask = [
		"(",
		/[1-9]/,
		/\d/,
		/\d/,
		")",
		" ",
		/\d/,
		/\d/,
		/\d/,
		" ",
		/\d/,
		/\d/,
		" ",
		/\d/,
		/\d/,
	];

	let brandType = 2;
	const [formUrl, setFormUrl] = useState();

	useEffect(() => {
		if (window.location.origin.includes("localhost")) {
			setFormUrl(
				"https://cloudex.testmedici.com/api/registration/client/Registration/RegisterUser"
			);
		} else if (window.location.origin.includes("test")) {
			setFormUrl(
				"https://cloudex.testmedici.com/api/registration/client/Registration/RegisterUser"
			);
		} else {
			//canlı domain
			setFormUrl(
				window.location.origin.replace("pro", "cloudex") +
				"/api/registration/client/Registration/RegisterUser"
			);
		}
	}, []);

	let timeIntervel;

	const [submitting, setSubmitting] = useState(false);
	const [time, setTime] = useState(0);

	const formatToYMD = (date) => {
		const [day, month, year] = date.split("/");
		return `${year}-${month}-${day}`;
	};

	const onSubmit = async (values) => {
		setSubmitting(true);

		const formattedDate = formatToYMD(values.dateofbirth);

		var data = {
			FirstName: values.name,
			LastName: values.surname,
			Email: values.email,
			Phone: String(values.phone)
				.replace(" ", "")
				.replace("(", "")
				.replace(")", "")
				.replace(" ", "")
				.replace(" ", ""),
			Password: values.password,
			IdentityNo: values.identity,
			dateOfBirth: formattedDate,
			referredBy: refcode !== "" ? refcode : values.referenceCode
		};

		try {
			axios
				.post(formUrl, data)
				.then((response) => {
					console.log(response)
					handleTime();
					return Swal.fire({
						title: t("successMessage"),
						text: t("successMessageText"),
						icon: "success",
					}).then(() => {
						window.location.reload();
					});
				})
				.catch((error) => {
					console.error(error.response.data.message);
					if (error.response.data.message === "Email already in use !!") {
						return Swal.fire({
							title: t("errorMessage"),
							text: t("emailexist"),
							icon: "warning",
						});
					}
					if (
						error.response.data.message === "Phone Number is already in use .."
					) {
						return Swal.fire({
							title: t("errorMessage"),
							text: t("phonenumberexist"),
							icon: "warning",
						});
					}
					if (
						error.response.data.message ===
						"Neither Phone Number or Email Address are unique"
					) {
						return Swal.fire({
							title: t("errorMessage"),
							text: t("phonenumberandemailexist"),
							icon: "warning",
						});
					}
					if (
						error.response.data.message.includes(
							"Unexpected character encountered"
						)
					) {
						return Swal.fire({
							title: t("errorMessage"),
							text: t("unexpectedcharacters"),
							icon: "warning",
						});
					}
					if (error.response.data.message === "Referrer Does Not Exist !!!") {
						return Swal.fire({
							title: t("errorMessage"),
							text: t("referencecodeerror"),
							icon: "warning",
						});
					}
					else {
						Swal.fire({
							title: t("errorMessage"),
							text: t("defaulterrormessage"),
							icon: "warning",
						});
					}
				});
			setSubmitting(false);
		} catch (error) {
			console.error("error", error);
			Swal.fire({
				title: t("errorMessage"),
				text: t("defaulterrormessage"),
				icon: "warning",
			});
		}
	};

	//timer settings start
	let timer = new Date();
	const handleTime = () => {
		let clickTime = timer.getTime();
		localStorage.setItem("cloudexTimer", clickTime);
		setTime(600000);
		reduceTime();
	};

	const reduceTime = async () => {
		if (localStorage.getItem("cloudexTimer")) {
			timeIntervel = setInterval(() => {
				setTime((time) => time - 1000);
			}, 1000);
		}
	};

	useEffect(() => {
		if (localStorage.getItem("cloudexTimer")) {
			let newTime = timer.getTime();
			let oldTime = localStorage.getItem("cloudexTimer");
			let timeDifference = newTime - oldTime;
			if (timeDifference > 600000) {
				setTime(0);
			} else {
				setTime(600000 - timeDifference);
				reduceTime();
			}
		}
	}, []);

	useEffect(() => {
		if (refcode !== "") {
			let index = refcode.indexOf("=");
			let result = refcode.substring(index + 1);
			setRefcode(result);
		}
	}, [refcode])

	function millisToMinutesAndSeconds(millis) {
		var minutes = Math.floor(millis / 60000);
		var seconds = ((millis % 60000) / 1000).toFixed(0);
		if (millis === 0 || millis < 0) {
			localStorage.removeItem("cloudexTimer");
			setTime(0);
			clearInterval(timeIntervel);
		}
		return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
	}
	//timer settings end

	return (
		<div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
			<h3 className="mb-6 text-2xl leading-snug font-medium">
				{t("createAccount")}
			</h3>
			<Formik
				initialValues={{
					name: "",
					surname: "",
					email: "",
					phone: "",
					terms: false,
					password: "",
					repassword: "",
					identity: "",
					dateofbirth: "",
					referenceCode: ""
				}}
				validationSchema={LoginSchema}
				onSubmit={(values) => {
					onSubmit(values);
				}}
			>
				{({ touched, errors, isSubmitting, setFieldValue }) => (
					<Form className="grid lg:grid-cols-12 grid-cols-1">
						<div className="lg:col-span-6 px-3 mb-6">
							<label htmlFor="name">{t("name")}</label>
							<Field
								type="text"
								name="name"
								placeholder={t("name")}
								className={`form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3 ${touched.name && errors.name ? "is-invalid" : ""
									}`}
							/>
							<ErrorMessage
								component="div"
								name="name"
								className="invalid-feedback text-start"
							/>
						</div>

						<div className="lg:col-span-6 px-3 mb-6">
							<label htmlFor="surname">{t("surname")}</label>
							<Field
								type="text"
								name="surname"
								placeholder={t("surname")}
								className={`form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3 ${touched.surname && errors.surname ? "is-invalid" : ""
									}`}
							/>
							<ErrorMessage
								component="div"
								name="surname"
								className="invalid-feedback text-start"
							/>
						</div>

						<div className="lg:col-span-6 px-3 mb-6">
							<label htmlFor="dateofbirth">{t("dateofbirth")}</label>
							<Field name="dateofbirth">
								{({ field }) => (
									<InputMask
										{...field}
										mask="99/99/9999"
										placeholder={t("dateplaceholder")}
										className={`form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3 ${touched.dateofbirth && errors.dateofbirth ? "is-invalid" : ""
											}`}
									/>
								)}
							</Field>
							{/* <Field
								type="date"
								name="dateofbirth"
								placeholder={t("dogum tarihi")}
								className={`form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3 ${touched.dateofbirth && errors.dateofbirth
									? "is-invalid"
									: new Date()
									}`}
							/> */}
							<ErrorMessage
								component="div"
								name="dateofbirth"
								className="invalid-feedback text-start"
							/>
						</div>

						<div className="lg:col-span-6 px-3 mb-6">
							<label htmlFor="email">{t("email")}</label>
							<Field
								type="email"
								name="email"
								placeholder={t("email")}
								className={`form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3 ${touched.email && errors.email ? "is-invalid" : ""
									}`}
							/>
							<ErrorMessage
								component="div"
								name="email"
								className="invalid-feedback text-start"
							/>
						</div>

						<div className="lg:col-span-6 px-3 mb-6">
							<label htmlFor="phone">{t("phone")}</label>
							<Field
								name="phone"
								render={({ field }) => (
									<MaskedInput
										{...field}
										mask={phoneNumberMask}
										placeholder="(5__) ___ __ __"
										// placeholder={t("phone")}
										id="phone"
										type="text"
										className={`form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3 ${touched.phone && errors.phone ? "is-invalid" : ""
											}`}
									/>
								)}
							/>
							<ErrorMessage
								component="div"
								name="phone"
								className="invalid-feedback text-start"
							/>
						</div>

						<div className="lg:col-span-6 px-3 mb-6">
							<label htmlFor="password">{t("password")}</label>
							<Field
								type="password"
								name="password"
								placeholder={t("password")}
								className={`form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3 ${touched.password && errors.password ? "is-invalid" : ""
									}`}
							/>
							<ErrorMessage
								component="div"
								name="password"
								className="invalid-feedback text-start"
							/>
						</div>

						<div className="lg:col-span-6 px-3 mb-4">
							<label htmlFor="repassword">{t("repassword")}</label>
							<Field
								type="password"
								name="repassword"
								placeholder={t("repassword")}
								className={`form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3 ${touched.repassword && errors.repassword ? "is-invalid" : ""
									}`}
							/>
							<ErrorMessage
								component="div"
								name="repassword"
								className="invalid-feedback text-start"
							/>
						</div>

						<div className="lg:col-span-6 px-3 mb-4">
							<label htmlFor="identity">{t("identity")}</label>
							<Field
								type="text"
								name="identity"
								placeholder={t("identity")}
								className={`form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3 ${touched.identity && errors.identity ? "is-invalid" : ""
									}`}
								maxLength={11}
							/>
							<ErrorMessage
								component="div"
								name="identity"
								className="invalid-feedback text-start"
							/>
						</div>

						<div className="lg:col-span-12 px-3 mb-4">
							<label htmlFor="referenceCode">{t("referenceCode")}</label>
							<Field
								type="text"
								name="referenceCode"
								placeholder={refcode != "" ? refcode : t("referenceCode")}
								className={`form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3 ${touched.referenceCode && errors.referenceCode ? "is-invalid" : ""
									}`}
								disabled={refcode !== ""}
							/>
							<ErrorMessage
								component="div"
								name="referenceCode"
								className="invalid-feedback text-start"
							/>
						</div>
						{time > 0 ? (
							<div className="lg:col-span-12 mt-4">
								<button
									type="submit"
									className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full w-full"
									disabled={true}
								>
									{millisToMinutesAndSeconds(time)} <span></span>
								</button>
							</div>
						) : (
							<div className="lg:col-span-12 mt-4">
								<button
									style={{ width: "100%" }}
									type="submit"
									className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full w-full"
									disabled={submitting}
								>
									{submitting ? t("sending") : t("send")} <span></span>
								</button>
							</div>
						)}
					</Form>
				)}
			</Formik>
		</div>
	);
}

export default CreateAccount;
