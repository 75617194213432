import React, { useEffect } from 'react'
import Navbar from '../../components/navbar'
import CollectionTwo from '../../components/collection-two'
import Footer from '../../components/footer';
import Switcher from '../../components/switcher';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TopImage from '../../components/TopImage';
import { Helmet } from 'react-helmet';
import aL from '../../assets/images/awards/AI.png'
import Edge from '../../assets/images/awards/EDGE.png'
import improved from '../../assets/images/awards/IMPROVED.png'

export default function Awards() {
    const { t } = useTranslation()
    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);

    const awardsData = [
        {
            title : "A.I. Trading Award",
            img:aL,
            description: t("aiTradingAward")
        },
        {
            title: "Most Improved Award",
            img:improved,
            description: t("mostImprovedAward")
        },
        {
            title: "Cutting-Edge Technology Award",
            img:Edge,
            description: t("cuttingEdgeTechAward")
        }
    ]

    return (
        <>
            <Helmet>
                <title>
                    {t("awards")}
                </title>
                <meta
                    name='Cloudex Awards - Recognized Excellence in Crypto Trading'
                    content="Learn about the awards and recognition that highlight Cloudex's commitment to excellence in crypto trading."
                />
            </Helmet>
            <Navbar />
            <TopImage title={t("awards")} />

            <section className="relative md:py-24 py-16">
                <CollectionTwo data={awardsData} />
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
