import React, { useState } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import about2 from "../../assets/images/800x600-whycloudex.png";
import Switcher from "../../components/switcher";
import { Hexagon } from "react-feather";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import { useTranslation } from "react-i18next";
import TopImage from "../../components/TopImage";
import { Helmet } from "react-helmet";
import JoinUs from "../index/components/joinUs";

export default function WhyBlockchain() {
	const { t } = useTranslation();

	const datas = [
		{
			icon: "uil uil-bright",
			title: t("whyBlockchainTitle1"),
			description: t("whyBlockchainText1"),
		},
		{
			icon: "uil uil-cloud-unlock",
			title: t("whyBlockchainTitle2"),
			description: t("whyBlockchainText2"),
		},
		{
			icon: "uil uil-share-alt",
			title: t("whyBlockchainTitle3"),
			description: t("whyBlockchainText3"),
		},
		{
			icon: "uil uil-file-check-alt",
			title: t("whyBlockchainTitle4"),
			description: t("whyBlockchainText4"),
		},
	];

	return (
		<>
			<Helmet>
				<title>{t("whyBlockchainImportant")}</title>
				<meta
					name="Why Cloudex? Discover the Benefits of Our Crypto Trading Platform"
					content="Explore the advantages of trading with Cloudex and take your crypto trading to the next level."
				/>
			</Helmet>

			<Navbar />
			<TopImage title={"whyBlockchainImportant"} />

			<section className="relative md:py-24 py-16">
				<div className="container">
					<div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
						<div className="lg:col-span-12">
							<h1 className="text-violet-600 font-medium mb-8 md:text-xxl text-4xl md:leading-snug leading-snug font-semibold">
								{t("whyBlockchainImportant")}
							</h1>

							{datas.map((e) => (
								<div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mb-6">
									<div className="md:col-span-2">
										<div className="relative overflow-hidden text-transparent -m-3">
											<Hexagon className="h-28 w-28 fill-violet-600/5 mx-auto rotate-[30deg]"></Hexagon>
											<div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-5xl flex align-middle justify-center items-center">
												<i className={e.icon}></i>
											</div>
										</div>
									</div>
									<div className="md:col-span-10">
										<h5 className="text-xl font-semibold mb-4 mt-8">
											{e.title}
										</h5>
										<p className="text-slate-400">{e.description}</p>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>
			<div className="mb-8">
				<JoinUs />
			</div>
			<Footer />
			<Switcher />
		</>
	);
}
