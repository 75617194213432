import React, { useState } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import image1 from "../assets/images/visionMision/1.png";
import Switcher from "../components/switcher";
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import { useTranslation } from "react-i18next";
import TopImage from "../components/TopImage";
import { Helmet } from "react-helmet";

export default function VisionMision() {
	const [isOpen, setOpen] = useState(false);
	const { t } = useTranslation();

	return (
		<>
			<Helmet>
				<title>{t("visionandmission")}</title>
				<meta
					name="Our Vision and Mission - Guiding Principles at Cloudex"
					content="Explore the vision and mission that drive us at Cloudex in providing exceptional crypto trading solutions."
				/>
			</Helmet>
			<Navbar />
			<TopImage title={t("visionandmission")} />

			<section className="relative md:py-24 py-16">
				<div className="container">
					<div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
						<div className="lg:col-span-5">
							<div className="relative">
								{/* <div className="absolute inset-0 bg-gradient-to-r from-red-600 to-violet-600 rounded-lg -mt-[15px] -ms-[15px] h-[98%] w-[98%] -z-1"></div> */}
								<img
									src={image1}
									className="rounded-lg shadow-md dark:shadow-gray-800"
									alt=""
								/>
								<div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center"></div>
							</div>
						</div>
						<div className="lg:col-span-7">
							<div className="lg:ms-5">
								<h2 className="text-violet-600 font-medium mb-4 md:text-xxl text-4xl md:leading-snug leading-snug font-semibold">
									{t("vision")}
								</h2>
								<p className="text-slate-400 mt-4">{t("visionp1")}</p>
								<p className="text-slate-400 mt-4">{t("visionp2")}</p>

								<h2 className="text-violet-600 font-medium mb-4 md:text-xxl text-4xl md:leading-snug leading-snug font-semibold mt-12">
									{t("mission")}
								</h2>
								<p className="text-slate-400 mt-4">{t("missionp1")}</p>
								<p className="text-slate-400 mt-4">{t("missionp2")}</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
			<Switcher />
		</>
	);
}
