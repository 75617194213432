import React, { useEffect, useState } from "react";

import logo_dark from "../assets/images/logo/logo-png.png";
import logo_white from "../assets/images/logo/logo-png.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { LANGUAGES } from "../constants/index";

export default function Navbar() {
	const [selectedLanguage, setSelectedLanguage] = useState(
		JSON.parse(localStorage.getItem("lang"))
			? JSON.parse(localStorage.getItem("lang"))
			: "en"
	);
	const { t, i18n } = useTranslation();
	const [isDropdown, openDropdown] = useState(true);
	const [isOpen, setMenu] = useState(true);

	const onChangeLang = (e) => {
		const lang_code = e.code;
		i18n.changeLanguage(lang_code);
		setSelectedLanguage(e.code);
	};

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			background: "none",
			border: "none",
			color: "#fff",
		}),
		menu: (provided, state) => ({
			...provided,
			background: "none",
			color: "#fff",
			width: "150px",
		}),
		option: (provided, state) => ({
			...provided,
			background: state.isFocused ? "#f0f0f0" : "white",
			color: "#000",
		}),
		singleValue: (provided, state) => ({
			...provided,
			color: "#fff",
		}),
		indicatorSeparator: (provided, state) => ({
			display: "none",
		}),
		dropdownIndicator: (provided, state) => ({
			display: "none",
		}),
	};

	useEffect(() => {
		activateMenu();
	});

	useEffect(() => {
		localStorage.setItem("lang", JSON.stringify(selectedLanguage));
	}, []);

	useEffect(() => {
		localStorage.setItem("lang", JSON.stringify(selectedLanguage));
	}, [selectedLanguage]);

	window.addEventListener("scroll", windowScroll);
	function windowScroll() {
		const navbar = document.getElementById("topnav");
		if (
			document.body.scrollTop >= 50 ||
			document.documentElement.scrollTop >= 50
		) {
			if (navbar !== null) {
				navbar?.classList.add("nav-sticky");
			}
		} else {
			if (navbar !== null) {
				navbar?.classList.remove("nav-sticky");
			}
		}

		const mybutton = document.getElementById("back-to-top");
		if (mybutton != null) {
			if (
				document.body.scrollTop > 500 ||
				document.documentElement.scrollTop > 500
			) {
				mybutton.classList.add("flex");
				mybutton.classList.remove("hidden");
			} else {
				mybutton.classList.add("hidden");
				mybutton.classList.remove("flex");
			}
		}
	}

	const toggleMenu = () => {
		setMenu(!isOpen);
		if (document.getElementById("navigation")) {
			const anchorArray = Array.from(
				document.getElementById("navigation").getElementsByTagName("a")
			);
			anchorArray.forEach((element) => {
				element.addEventListener("click", (elem) => {
					const target = elem.target.getAttribute("href");
					if (target !== "") {
						if (elem.target.nextElementSibling) {
							var submenu = elem.target.nextElementSibling.nextElementSibling;
							submenu.classList.toggle("open");
						}
					}
				});
			});
		}
	};

	const getClosest = (elem, selector) => {
		// Element.matches() polyfill
		if (!Element.prototype.matches) {
			Element.prototype.matches =
				Element.prototype.matchesSelector ||
				Element.prototype.mozMatchesSelector ||
				Element.prototype.msMatchesSelector ||
				Element.prototype.oMatchesSelector ||
				Element.prototype.webkitMatchesSelector ||
				function (s) {
					var matches = (this.document || this.ownerDocument).querySelectorAll(
							s
						),
						i = matches.length;
					while (--i >= 0 && matches.item(i) !== this) {}
					return i > -1;
				};
		}

		// Get the closest matching element
		for (; elem && elem !== document; elem = elem.parentNode) {
			if (elem.matches(selector)) return elem;
		}
		return null;
	};

	const activateMenu = () => {
		var menuItems = document.getElementsByClassName("sub-menu-item");
		// console.log(menuItems)
		if (menuItems) {
			var matchingMenuItem = null;
			for (var idx = 0; idx < menuItems.length; idx++) {
				// console.log(menuItems[idx])
				// if(menuItems[idx].classList.value === 'sub-menu-item active'){
				//     console.log('worked')
				//     menuItems[idx].classList.value = 'sub-menu-item'
				// }
				if (menuItems[idx].href === window.location.href) {
					matchingMenuItem = menuItems[idx];
				}
			}

			if (matchingMenuItem) {
				matchingMenuItem.classList.add("active");

				var immediateParent = getClosest(matchingMenuItem, "li");

				if (immediateParent) {
					const parentUl = immediateParent.parentNode;
					const othersLi = parentUl.getElementsByTagName("li");
					for (let i = 0; i < othersLi.length; i++) {
						othersLi[i].classList = "";
					}
					immediateParent.classList.add("active");
				}

				var parent = getClosest(immediateParent, ".child-menu-item");
				if (parent) {
					parent.classList.add("active");
				}

				var parent = getClosest(parent || immediateParent, ".parent-menu-item");

				if (parent) {
					parent.classList.add("active");

					var parentMenuitem = parent.querySelector(".menu-item");
					if (parentMenuitem) {
						parentMenuitem.classList.add("active");
					}

					var parentOfParent = getClosest(parent, ".parent-parent-menu-item");
					if (parentOfParent) {
						parentOfParent.classList.add("active");
					}
				} else {
					var parentOfParent = getClosest(
						matchingMenuItem,
						".parent-parent-menu-item"
					);
					if (parentOfParent) {
						// console.log(parentOfParent)
						// console.log('worked7')
						parentOfParent.classList.add("active");
					}
				}
			}
		}
	};

	const metamask = async () => {
		try {
			//Basic Actions Section
			const onboardButton = document.getElementById("connectWallet");

			//   metamask modal
			const modal = document.getElementById("modal-metamask");
			const closeModalBtn = document.getElementById("close-modal");

			//   wallet address
			const myPublicAddress = document.getElementById("myPublicAddress");

			//Created check function to see if the MetaMask extension is installed
			const isMetaMaskInstalled = () => {
				//Have to check the ethereum binding on the window object to see if it's installed
				const { ethereum } = window;
				return Boolean(ethereum && ethereum.isMetaMask);
			};

			const onClickConnect = async () => {
				if (!isMetaMaskInstalled()) {
					//meta mask not installed
					modal.classList.add("show");
					modal.style.display = "block";
					return;
				}
				try {
					// eslint-disable-next-line no-undef
					await ethereum.request({ method: "eth_requestAccounts" });
					// eslint-disable-next-line no-undef
					const accounts = await ethereum.request({ method: "eth_accounts" });
					myPublicAddress.innerHTML =
						accounts[0].split("").slice(0, 6).join("") +
						"..." +
						accounts[0]
							.split("")
							.slice(accounts[0].length - 7, accounts[0].length)
							.join("");
				} catch (error) {
					console.error(error);
				}
			};

			const closeModal = () => {
				modal.classList.remove("show");
				modal.style.display = "none";
			};

			if (isMetaMaskInstalled()) {
				// eslint-disable-next-line no-undef
				const accounts = await ethereum.request({ method: "eth_accounts" });
				if (!!accounts[0]) {
					myPublicAddress.innerHTML =
						accounts[0].split("").slice(0, 6).join("") +
						"..." +
						accounts[0]
							.split("")
							.slice(accounts[0].length - 7, accounts[0].length)
							.join("");
				}
			}

			onboardButton.addEventListener("click", onClickConnect);
			closeModalBtn.addEventListener("click", closeModal);
		} catch (error) {}
	};

	return (
		<>
			<nav id="topnav" className="defaultscroll is-sticky py-4">
				<div
					style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
					className="container"
				>
					{/* <!-- Logo container--> */}
					<Link className="logo ps-0" to="/">
						<img
							src={logo_dark}
							className="inline-block sm:hidden"
							style={{ height: "4rem" }}
							alt=""
						/>
						<div className="sm:block hidden">
							<img
								src={logo_dark}
								className="inline-block dark:hidden"
								style={{ height: "4rem" }}
								alt=""
							/>
							<img
								src={logo_white}
								className="hidden dark:inline-block"
								alt="logo"
								width="100px"
							/>
						</div>
					</Link>

					<div className="menu-extras">
						<div className="menu-item flex items-center">
							<Select
								className="language-select-box"
								defaultValue={
									LANGUAGES.find((lang) => lang.code === selectedLanguage)
										? LANGUAGES.find((lang) => lang.code === selectedLanguage)
										: LANGUAGES.find((lang) => lang.code === "en")
								}
								onChange={onChangeLang}
								styles={customStyles}
								isSearchable={false}
								options={LANGUAGES}
								getOptionLabel={(option) => (
									<div className="flex items-center">
										<img
											src={option.flag}
											alt={option.label}
											width="30px"
											className="me-2"
										/>
										{option.label}
									</div>
								)}
							/>
							{/* <!-- Mobile menu toggle--> */}
							<a
								className="navbar-toggle"
								id="isToggle"
								onClick={toggleMenu}
								style={{ marginLeft: "25px" }}
							>
								<div className="lines">
									<span></span>
									<span></span>
									<span></span>
								</div>
							</a>
						</div>
					</div>

					{/* <!--Login button Start--> */}
					<ul className="buy-button list-none mb-0"></ul>
					{/* <button onClick={() => i18n.changeLanguage('de')}>Change Language</button> */}
					<div
						id="navigation"
						className={`${isOpen === true ? "hidden" : "block"}`}
					>
						<ul className="navigation-menu justify-end items-center">
							<li className="has-submenu parent-menu-item">
								<Link to="/">{t("home")}</Link>
							</li>

							<li className="has-submenu parent-menu-item">
								<Link to="#">{t("aboutUs")}</Link>
								<span className="menu-arrow"></span>
								<ul className="submenu">
									<li>
										<Link to="/about-ceo" className="sub-menu-item">
											{t("CEOintroduction")}
										</Link>
									</li>
									<li>
										<Link to="/why-cloudex" className="sub-menu-item">
											{t("whyChooseCloudex")}
										</Link>
									</li>
									<li>
										<Link to="/awards" className="sub-menu-item">
											{t("awards")}
										</Link>
									</li>
									<li>
										<Link to="/faqs" className="sub-menu-item">
											{t("faq")}
										</Link>
									</li>
									{/* <li><Link to="/item-detail" className="sub-menu-item"> {t('Legal Documents')}</Link></li> */}
									{/* <li><Link to="/activity" className="sub-menu-item">{t('Sodical Media Accounts')}</Link></li> */}
									<li>
										<Link to="/ai-trading" className="sub-menu-item">
											{t("AI Trading")}
										</Link>
									</li>
									<li>
										<Link to="/cloudex-insurance" className="sub-menu-item">
											{t("cloudexInsurance")}
										</Link>
									</li>
									<li>
										<Link to="/references" className="sub-menu-item">
											{t("referralProgram")}
										</Link>
									</li>
								</ul>
							</li>

							<li className="has-submenu parent-parent-menu-item">
								<Link to="#">{t("academy")}</Link>
								<span className="menu-arrow"></span>
								<ul className="submenu">
									<li>
										<Link
											to="/why-invest-cryptocurrencies"
											className="sub-menu-item"
										>
											{t("whyInvestCrypto")}
										</Link>
									</li>
									<li>
										<Link
											to="/why-blockchain-important"
											className="sub-menu-item"
										>
											{t("whyBlockchainImportant")}
										</Link>
									</li>
									<li>
										<Link to="/crypto-dictionary" className="sub-menu-item">
											{t("cryptocurrencyDictionary")}
										</Link>
									</li>
								</ul>
							</li>

							<li className="has-submenu parent-menu-item">
								<Link to="/announcements">{t("announcements")}</Link>
							</li>

							<li className="has-submenu parent-menu-item">
								<Link to="/news">{t("news")}</Link>
							</li>

							<li className="has-submenu parent-menu-item">
								<Link to="/account">{t("signup")}</Link>
							</li>

							<li className="has-submenu parent-menu-item">
								<Link to="https://pro.cloudexcrypto.com/" target="_blank">
									{t("login")}
								</Link>
							</li>
							<li className="has-submenu parent-menu-item">
								<Select
									className="language-select-box"
									defaultValue={
										LANGUAGES.find((lang) => lang.code === selectedLanguage)
											? LANGUAGES.find((lang) => lang.code === selectedLanguage)
											: LANGUAGES.find((lang) => lang.code === "en")
									}
									onChange={onChangeLang}
									styles={customStyles}
									isSearchable={false}
									options={LANGUAGES}
									getOptionLabel={(option) => (
										<div className="flex items-center">
											<img
												src={option.flag}
												alt={option.label}
												width="30px"
												className="me-2"
											/>
											{option.label}
										</div>
									)}
								/>
							</li>

							{/* <li className="has-submenu parent-parent-menu-item navbarArrow">
								<Link to="#">{t("accountTransactions")}</Link>
								<span className="menu-arrow"></span>
								<span className="menu-arrow"></span>
								<ul className="submenu">
									<li>
										<Link to="/real-account" className="sub-menu-item">
											{t("login")}
										</Link>
									</li>
									<li>
										<Link to="/account" className="sub-menu-item">
											{t("signup")}
										</Link>
									</li>
								</ul>
							</li> */}

							{/* <li className="has-submenu parent-menu-item navbarArrow">
								<Link to="/contact">{t("contact")}</Link>
							</li> */}

							{/* <li className="has-submenu parent-menu-item">
								
							</li> */}

							<li className="has-submenu parent-parent-menu-item navbarArrowMain">
								<Link to="#"></Link>
								<i className="uil uil-angle-double-right navbarIcon"></i>
								<ul className="submenu secondArrow">
									<li className="has-submenu parent-menu-item">
										<Link to="#"> {t("accountTransactions")} </Link>
										<span className="submenu-arrow"></span>
										<ul className="submenu secondArrow2">
											<li>
												<Link to="/real-account" className="sub-menu-item">
													{t("login")}
												</Link>
											</li>
											<li>
												<Link to="/account" className="sub-menu-item">
													{t("signup")}
												</Link>
											</li>
										</ul>
									</li>
									{/* <li>
										<Link to="/contact" className="sub-menu-item">
											{t("contact")}
										</Link>
									</li> */}
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
}
