import React, { useReducer } from "react";
import Navbar from "../../components/navbar";
import { Link as Link2 } from "react-scroll";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import { Link } from "react-router-dom";
// import faqData from "../../data/faqData";
import TopImage from "../../components/TopImage";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function Faqs() {
	const { t } = useTranslation();
	const initialState = {
		activeIndex: 0,
		activeGeneral: 0,
		activePayment: 0,
		activeSupport: 0,
	};

	const toggleAccordion = (category, index) => {
		dispatch({
			type: "SELECT_CATEGORY",
			payload: { category: category, index: index },
		});
	};

	const cryptoFAQ = [
		{
			question: t("question[1]"),
			answer: t("answer[1]"),
		},
		{
			question: t("question[2]"),
			answer: t("answer[2]"),
		},
		{
			question: t("question[3]"),
			answer: t("answer[3]"),
		},
		{
			question: t("question[4]"),
			answer: t("answer[4]"),
		},
		{
			question: t("question[5]"),
			answer: t("answer[5]"),
		},
		{
			question: t("question[6]"),
			answer: t("answer[6]"),
		},
		{
			question: t("question[7]"),
			answer: t("answer[7]"),
		},
		{
			question: t("question[1]"),
			answer: t("answer[1]"),
		},
		{
			question: t("question[8]"),
			answer: t("answer[8]"),
		},
		{
			question: t("question[9]"),
			answer: t("answer[9]"),
		},
		{
			question: t("question[10]"),
			answer: t("answer[10]"),
		},
		{
			question: t("question[11]"),
			answer: t("answer[11]"),
		},
		{
			question: t("question[12]"),
			answer: t("answer[12]"),
		},
		{
			question: t("question[13]"),
			answer: t("answer[13]"),
		},
		{
			question: t("question[14]"),
			answer: t("answer[14]"),
		},
		{
			question: t("question[15]"),
			answer: t("answer[15]"),
		},
		{
			question: t("question[16]"),
			answer: t("answer[16]"),
		},
		{
			question: t("question[17]"),
			answer: t("answer[17]"),
		},
		{
			question: t("question[18]"),
			answer: t("answer[18]"),
		},
		{
			question: t("question[19]"),
			answer: t("answer[19]"),
		},
		{
			question: t("question[20]"),
			answer: t("answer[20]"),
		},
		{
			question: t("question[21]"),
			answer: t("answer[21]"),
		},
		{
			question: t("question[22]"),
			answer: t("answer[22]"),
		},
		{
			question: t("question[23]"),
			answer: t("answer[23]"),
		},
		{
			question: t("question[24]"),
			answer: t("answer[24]"),
		},
		{
			question: t("question[25]"),
			answer: t("answer[25]"),
		},
		{
			question: t("question[26]"),
			answer: t("answer[26]"),
		},
	];

	const reducerMethod = (state, action) => {
		switch (action.payload.category) {
			case "active":
				return {
					...state,
					activeIndex: action.payload.index,
				};
			case "general":
				return {
					...state,
					activeGeneral: action.payload.index,
				};
			case "payments":
				return {
					...state,
					activePayment: action.payload.index,
				};
			case "support":
				return {
					...state,
					activeSupport: action.payload.index,
				};
			default:
				return state;
		}
	};
	const [state, dispatch] = useReducer(reducerMethod, initialState);

	return (
		<>
			<Helmet>
				<title>{t("faq")}</title>
				<meta
					name="Frequently Asked Questions - Cloudex Crypto Trading Platform"
					content="
                    Get answers to common questions about Cloudex and our crypto trading services."
				/>
			</Helmet>
			<Navbar />
			<TopImage title={"faq"} />
			<section className="relative md:py-24 py-16">
				<div className="container">
					<div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
						<div className="lg:col-span-12 md:col-span-12">
							<div id="tech">
								{/* <h5 className="text-2xl font-semibold">Buying Product</h5> */}

								<div id="accordion-collapseone" data-accordion="collapse">
									{cryptoFAQ.map((item, index) => (
										<div
											key={index}
											className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4"
										>
											<h2
												className="text-base font-semibold"
												id="accordion-collapse-heading-1"
											>
												<button
													onClick={() => toggleAccordion("active", index)}
													type="button"
													className={`flex justify-between items-center p-5 w-full font-semibold text-start ${
														state.activeIndex === index
															? "bg-gray-50 dark:bg-slate-800 text-violet-600"
															: ""
													}`}
													data-accordion-target="#accordion-collapse-body-1"
													aria-expanded="true"
													aria-controls="accordion-collapse-body-1"
												>
													<span>{item.question}</span>
													<svg
														data-accordion-icon
														className="w-4 h-4 rotate-180 shrink-0"
														fill="currentColor"
														viewBox="0 0 20 20"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fillRule="evenodd"
															d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
															clipRule="evenodd"
														></path>
													</svg>
												</button>
											</h2>
											{state.activeIndex === index && (
												<div
													id="accordion-collapse-body-1"
													aria-labelledby="accordion-collapse-heading-1"
												>
													<div className="p-5">
														<p className="text-slate-400 dark:text-gray-400">
															{item.answer}
														</p>
													</div>
												</div>
											)}
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
			<Switcher />
		</>
	);
}
