import React from "react";
import { Link } from "react-router-dom";
import TinySlider from "tiny-slider-react";
import image1 from "../../../assets/images/home/trading-platforms.png";
import { useTranslation } from "react-i18next";
import CreateAccount from "../../../components/createAccount";

const settings = {
	items: 1,
	controls: true,
	mouseDrag: true,
	loop: true,
	rewind: true,
	autoplay: true,
	autoplayButtonOutput: false,
	autoplayTimeout: 3000,
	navPosition: "bottom",
	nav: false,
	speed: 400,
	gutter: 0,
	controlsText: [
		'<i class="mdi mdi-chevron-left "></i>',
		'<i class="mdi mdi-chevron-right"></i>',
	],
};

function JoinUs() {
	const { t } = useTranslation();

	return (
		<section className="relative overflow-hidden">
			<div className="container-fluid lg:px-10 md:px-3 relative overflow-hidden mb-8">
				<span className="absolute blur-[200px] w-[600px] h-[600px] rounded-full top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 bg-gradient-to-tl from-red-600/40 to-violet-600/40 dark:from-red-600/60 dark:to-violet-600/60"></span>
				<div className="lg:py-24 py-[74px] md:rounded-lg shadow dark:shadow-gray-800 bg-violet-700/10 dark:bg-violet-600/20">
					<div className="container">
						<div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
							<div className="md:col-span-5">
								<div className="md:me-6">
									<h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4">
										<span className="bg-gradient-to-l from-red-600 to-violet-600 text-transparent bg-clip-text">
											{t("joinUs")}
										</span>
									</h4>
									<p className="text-lg max-w-xl">{t("joinUsText")}</p>
								</div>
								<div className="overflow-hidden after:content-[''] after:absolute after:h-10 after:w-10 after:bg-violet-600/10 dark:after:bg-violet-600/30 after:-bottom-[50px] after:start-[30%] after:-z-1 after:rounded-full after:animate-ping"></div>
							</div>

							<div className="md:col-span-7 relative">
								<div className="tiny-one-icon-item">
									<div className="tiny-slide">
										<div className="m-2 p-3 bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
											<CreateAccount />
										</div>
									</div>
								</div>

								<div className="overflow-hidden after:content-[''] after:absolute after:h-14 after:w-14 after:bg-violet-600/10 dark:after:bg-violet-600/30 after:-top-[50px] after:start-[30%] after:-z-1 after:rounded-lg after:animate-[spin_10s_linear_infinite]"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default JoinUs;
