import React from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Switcher from "../components/switcher";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TopImage from "../components/TopImage";
import { Helmet } from "react-helmet";

export default function CookiePolicy() {
	const { t } = useTranslation();
	return (
		<>
			<Helmet>
				<title>Cookie Policy</title>
				<meta
					name="Cookie Policy - Understanding How Cookies are Used at
                    CloudEx"
					content="Understand how cookies are used on CloudEx's website
                    with our detailed cookie policy."
				/>
			</Helmet>
			<Navbar />
			<TopImage title={"Cookie Policy"} />

			<section className="relative md:py-24 py-16">
				<div className="container">
					<div className="md:flex justify-center">
						<div className="md:w-3/4">
							<div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
								<>
									<h5 className="text-large font-semibold mb-4 mt-4 text-violet-600">
										1. {t("introduction")}
									</h5>
									<p className="text-slate-400">{t("cookieIntroText")}</p>
								</>
								<>
									<h5 className="text-large font-semibold mb-4 text-violet-600 mt-4">
										2. {t("whatAreCookies")}
									</h5>
									<p className="text-slate-400">{t("whatAreCookiesText")}</p>
								</>
								<>
									<h5 className="text-large font-semibold mb-4 text-violet-600 mt-4">
										3. {t("howWeUseCookies")}
									</h5>
									<p className="text-slate-400">{t("howWeUseCookiesText")}</p>
									<ul className="list-none text-slate-400 mt-3">
										<li className="mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
												<span className="font-bold">
													{t("essentialCookies")}{" "}
												</span>
											</div>
											<div> {t("essentialCookiesText")}</div>
										</li>
										<li className="mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
												<span className="font-bold">
													{t("analyticalCookies")}
												</span>
											</div>
											<div>{t("analyticalCookiesText")}</div>
										</li>
										<li className="mt-2">
											<div>
												<i className="uil uil-arrow-right text-violet-600 text-lg align-middle me-2"></i>
												<span className="font-bold">
													{t("advertisingCookies")}
												</span>
											</div>
											<div>{t("advertisingCookiesText")}</div>
										</li>
									</ul>
								</>
								<>
									<h5 className="text-large font-semibold mb-4 text-violet-600 mt-4">
										4. {t("yourCookieChoices")}
									</h5>
									<p className="text-slate-400">{t("yourCookieChoicesText")}</p>
								</>
								<>
									<h5 className="text-large font-semibold mb-4 text-violet-600 mt-4">
										5. {t("thirdPartyCookies")}
									</h5>
									<p className="text-slate-400">{t("thirdPartyCookiesText")}</p>
								</>
								<>
									<h5 className="text-large font-semibold mb-4 text-violet-600 mt-4">
										6. {t("changestothisPolicy")}
									</h5>
									<p className="text-slate-400">
										{t("cookieChangestothisPolicyText")}
									</p>
								</>
								<>
									<h5 className="text-large font-semibold mb-4 text-violet-600 mt-4">
										7. {t("contact")}
									</h5>
									<p className="text-slate-400">{t("cookieContactUsText")}</p>
								</>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
			<Switcher />
		</>
	);
}
