const Li_A = ({ nameIco, iconLink, key }) => (
	<li key={key}>
		<a target="_blank" href={iconLink}>
			<i className={nameIco} aria-hidden="true"></i>
		</a>
	</li>
);

const VerticalSocialIcons = () => {
	const VerticalSocial = [
		{
			nameIco: "uil uil-instagram",
			iconLink: "https://www.instagram.com/cloudexcrypto",
		},
		{
			nameIco: "uil uil-facebook-f",
			iconLink: "https://www.facebook.com/cloudexcrypto",
		},
		{
			nameIco: "fa-brands fa-x-twitter",
			iconLink: "https://x.com/cloudexcrypto",
		},
		{
			nameIco: "fa-brands fa-threads",
			iconLink: "https://www.threads.net/@cloudexcrypto",
		},
	];

	return (
		<>
			<div className="vertical-social">
				<ul>
					{VerticalSocial &&
						VerticalSocial.map((item, key) => (
							<Li_A key={key} nameIco={item.nameIco} iconLink={item.iconLink} />
						))}
				</ul>
			</div>
		</>
	);
};

export default VerticalSocialIcons;
