import React from "react";

import Footer from "../../components/footer";

import contact from "../../assets/images/contact.png";
import Switcher from "../../components/switcher";
import { Link } from "react-router-dom";
import CreateAccount from "../../components/createAccount";
import TopImage from "../../components/TopImage";
import Navbar from "../../components/navbar";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function Account() {
	const { t } = useTranslation();
	return (
		<>
			<Helmet>
				<title>{t("signup")}</title>
				<meta
					name="Open a Real Crypto Trading Account - Cloudex"
					content="
                    Start your real crypto trading journey with Cloudex. Open an account today and seize trading opportunities."
				/>
			</Helmet>
			<Navbar />
			<TopImage title={t("signup")} />

			<section className="relative">
				<div className="container lg:px-2 p-0">
					<div className="container lg:px-2 p-0">
						<div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
							<div className="lg:col-span-6 md:col-span-6 order-1 md:order-2">
								<img src={contact} alt="" />
							</div>

							<div className="lg:col-span-6 md:col-span-6 mt-2 md:mt-0 order-2 md:order-1">
								<div className="lg:me-5">
									<CreateAccount />
								</div>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px] my-10">
						<div className="text-center px-6 mt-6">
							<div className="w-20 h-20 bg-violet-600/5 text-violet-600 rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
								<i className="uil uil-phone"></i>
							</div>

							<div className="content mt-7">
								<h5 className="title text-xl font-semibold">{t("phone")}</h5>
								<p className="text-slate-400 mt-3">{t("contactPhone")}</p>

								<div className="mt-5">
									<a
										href="https://wa.me/447438488658"
										target="_blank"
										className="btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out"
									>
										+44 7438 488658
									</a>
								</div>
							</div>
						</div>

						<div className="text-center px-6 mt-6">
							<div className="w-20 h-20 bg-violet-600/5 text-violet-600 rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
								<i className="uil uil-envelope"></i>
							</div>

							<div className="content mt-7">
								<h5 className="title text-xl font-semibold">{t("email")}</h5>
								<p className="text-slate-400 mt-3">{t("contactMail")}</p>

								<div className="mt-5">
									<a
										href="mailto:support@cloudexcrypto.com"
										className="btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out"
									>
										support@cloudexcrypto.com
									</a>
								</div>
							</div>
						</div>

						<div className="text-center px-6 mt-6">
							<div className="w-20 h-20 bg-violet-600/5 text-violet-600 rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
								<i className="uil uil-map-marker"></i>
							</div>

							<div className="content mt-7">
								<h5 className="title text-xl font-semibold">{t("location")}</h5>
								<p className="text-slate-400 mt-3">{t("address")}</p>

								<div className="mt-5">
									<a
										target="_blank"
										href="https://maps.app.goo.gl/w3eokxPVX56QaQn37"
										data-type="iframe"
										className="video-play-icon read-more lightbox btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out"
									>
										View on Google map
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<div className="container-fluid relative">
				<div className="grid grid-cols-1">
					<div className="w-full leading-[0] border-0">
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2374.391407177949!2d-2.24473842300619!3d53.47933596476419!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb1c1b1342fa1%3A0x161114a609792e7f!2s32%20Booth%20St%2C%20Manchester%20M2%204AB%2C%20UK!5e0!3m2!1sen!2sbg!4v1714402151643!5m2!1sen!2sbg"
							style={{ border: 0 }}
							className="w-full h-[500px]"
							allowFullScreen
						></iframe>
					</div>
				</div>
			</div>

			<Footer />
			<Switcher />
		</>
	);
}
