import React from "react";
import { Link } from "react-router-dom";
import { Hexagon } from "react-feather";
import { useTranslation } from "react-i18next";

export default function WhyCloudex() {
	const { t } = useTranslation();

	const datas = [
		{
			icon: "uil uil-bright",
			title: t("whyTitle1"),
			description: t("whyText1"),
			link: "/why-cloudex",
		},
		{
			icon: "uil uil-rss-interface",
			title: t("whyTitle2"),
			description: t("whyText2"),
			link: "/why-cloudex",
		},
		{
			icon: "uil uil-coins",
			title: t("whyTitle3"),
			description: t("whyText3"),
			link: "/why-cloudex",
		},
		{
			icon: "uil uil-money-withdrawal",
			title: t("whyTitle4"),
			description: t("whyText4"),
			link: "/why-cloudex",
		},
		{
			icon: "uil uil-lock-alt",
			title: t("whyTitle5"),
			description: t("whyText5"),
			link: "/why-cloudex",
		},
		{
			icon: "uil uil-cloud-question",
			title: t("whyTitle6"),
			description: t("whyText6"),
			link: "/why-cloudex",
		},
	];

	return (
		<div className="container md:mt-12 mt-16">
			<div className="grid grid-cols-1 text-center">
				<h1 className="mb-4 md:text-5xl text-3xl md:leading-snug leading-snug font-semibold bg-gradient-to-r from-red-600 to-violet-600 text-transparent bg-clip-text">
					{t("whyChooseCloudex")}
				</h1>

				{/* <p className="text-slate-400 max-w-xl mx-auto">{t('We are a huge marketplace dedicated to connecting great artists of all Giglink with their fans and unique token collectors!')}</p> */}
			</div>

			<div
				style={{ marginBottom: "5rem" }}
				className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-10 gap-[30px]"
			>
				{datas.map((e) => (
					<div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
						<div className="relative overflow-hidden text-transparent -m-3">
							<Hexagon className="h-28 w-28 fill-violet-600/5 mx-auto rotate-[30deg]"></Hexagon>
							<div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-5xl flex align-middle justify-center items-center">
								<i className={e.icon}></i>
							</div>
						</div>

						<div className="mt-6">
							<Link
								to={e.link}
								className="text-lg h5 font-semibold transition duration-500 ease-in-out hover:text-violet-600"
							>
								{e.title}
							</Link>
							<p className="text-slate-400 transition duration-500 ease-in-out mt-3 ellipsis-three-line">
								{e.description}
							</p>
						</div>

						<div className="mt-4">
							<Link
								to={e.link}
								className="text-[16px] text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
							>
								{t("detail")}
								<i className="uil uil-angle-right-b ms-2"></i>
							</Link>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
