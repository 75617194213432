import React, { useState } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import about from "../../assets/images/new-images/ceo.webp";
import Switcher from "../../components/switcher";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import { useTranslation } from "react-i18next";
import TopImage from "../../components/TopImage";
import { Helmet } from "react-helmet";

export default function AboutCeo() {
	const [isOpen, setOpen] = useState(false);
	const { t } = useTranslation();

	return (
		<>
			<Helmet>
				<title>{t("abouttheCeo")}</title>
				<meta
					name="Sirix - Your Crypto Trading Companion at
                    CloudEx"
					content="Discover the features and benefits of Sirix,
                    your trusted companion for Crypto trading at CloudEx."
				/>
			</Helmet>
			<Navbar />
			<TopImage title={"abouttheCeo"} />

			<section className="relative md:py-24 py-16">
				<div className="container">
					<div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
						<div className="lg:col-span-6">
							<div className="lg:ms-5">
								{/* <h5 className="text-xl font-semibold mb-4 mt-8">{t('Sirix: Advanced Technology and User-Friendly Experience in Financial Markets')}</h5> */}
								<p className="text-slate-400">{t("ceop1")}</p>
								<p className="text-slate-400 mt-5">{t("ceop2")}</p>
								<p className="text-slate-400 mt-5">{t("ceop3")}</p>
								<p className="text-slate-400 mt-5">{t("ceop4")}</p>
								<p className="text-slate-400 mt-5">{t("ceop5")}</p>
							</div>
						</div>
						<div className="lg:col-span-1"></div>
						<div className="lg:col-span-5">
							<div className="relative">
								{/* <div className="absolute inset-0 bg-gradient-to-r from-red-600 to-violet-600 rounded-lg -mt-[15px] -ms-[15px] h-[98%] w-[98%] -z-1"></div> */}
								<img
									src={about}
									className="rounded-lg shadow-md dark:shadow-gray-800"
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
			<Switcher />
		</>
	);
}
