import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import TopImage from "../../components/TopImage";
import { Helmet } from "react-helmet";

const News = () => {
	const { i18n, t } = useTranslation();
	const [currentLang, setCurrentLang] = useState(
		JSON.parse(localStorage.getItem("lang"))
	);

	const getData = () => {
		const script = document.createElement("script");
		script.src = "https://static.cryptopanic.com/static/js/widgets.min.js";
		script.async = true;
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	};

	useEffect(() => {
		// window.location.reload();
	}, [currentLang]);

	useEffect(() => {
		const langFromLocalStorage = JSON.parse(localStorage.getItem("lang"));
		setCurrentLang(langFromLocalStorage);
		getData();
	}, []);

	return (
		<>
			<Helmet>
				<title>{t("news")}</title>
				<meta
					name="Crypto Dictionary - Key Terms and Definitions in Currency
                    Trading"
					content="Explore our Crypto dictionary to understand the essential
                    terms and definitions used in currency trading."
				/>
			</Helmet>
			<Navbar />
			<TopImage title={t("news")} />
			<section className="relative md:py-24 py-16">
				<div className="container">
					<div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
						<div className="lg:col-span-12 md:col-span-12">
							<div className="container my-4">
								<a
									href="https://cryptopanic.com/"
									target="_blank"
									data-news_feed="recent"
									data-bg_color="#FFFFFF"
									data-text_color="#100D28"
									data-link_color="#100D28"
									data-header_bg_color="#100D28"
									data-header_text_color="#FFFFFF"
									data-posts_limit="10"
									className="CryptoPanicWidget"
								>
									{t("latestNews")}
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
			<Switcher />
		</>
	);
};

export default News;
